const getQueryParam = (name: string) => {
    return new URLSearchParams(window.location.search).get(name);
};

export const CHECKOUT_ID = getQueryParam('id') || '';

export const CLIENT_TOKEN = getQueryParam('client_token') || '';

export const IS_MOBILE = Boolean(window.matchMedia('(max-width: 966px)').matches);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const IS_CHROME = Boolean(window.chrome);

export const GLOBAL_CLASS_NAMES = {
    formItemWithError: 'global_form-item-with-error',
};
