import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import Modal from '../../ui/modal/Modal';
import Button from '../../ui/button';
import { IS_MOBILE } from '../../constants';
import { deleteProduct } from '../../store/orderSlice';
import { Product } from '../../store/types';

import styles from './DeleteProductModal.module.css';


interface DeleteProductModalProps {
    visible: boolean;
    onClose: () => void;
    product: Product;
}

const DeleteProductModal = ({ visible, onClose, product }: DeleteProductModalProps):JSX.Element => {
    const dispatch = useDispatch();
    const classNameWrapper = classNames(styles.Wrapper, { [ styles.IsMobile ]: IS_MOBILE });

    const onClickDelete = () => {
        dispatch(deleteProduct(product));
        onClose();
    };

    return (
        <Modal
            visible={ visible }
            onClose={ onClose }
        >
            <div className={ classNameWrapper }>
                <div className={ styles.Title }>
                    Вы действительно хотите удалить товар из корзины?
                </div>
                <div className={ styles.ButtonWrapper }>
                    <div className={ styles.LeaveButton }>
                        <Button
                            type="normal"
                            onClick={ onClose }
                        >
                            Оставить
                        </Button>
                    </div>
                    <div>
                        <Button
                            type="normal-outline"
                            onClick={ onClickDelete }
                        >
                            Удалить
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteProductModal;
