import { FunctionComponent } from 'react';

import { IS_MOBILE } from '../constants';

interface WithPlatformProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mobile: FunctionComponent<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    desktop: FunctionComponent<any>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function withPlatform({ mobile, desktop }: WithPlatformProps): FunctionComponent<any> {
    return IS_MOBILE ? mobile : desktop;
}
