import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const orderPersistConfig = {
    key: 'order',
    version: 1,
    storage: storage,
    whitelist: [ 
        'personality',
        'city',
        'address',
        'pvz',
        'lastUpdatedAt',
    ],
};

import { reducer as orderReducer } from './orderSlice';
import { reducer as deliveriesReducer } from './deliveriesSlice';
import { reducer as paymentMethodsReducer } from './paymentMethodsSlice';
import { reducer as formStateReducer } from './formStateSlice';

export const store = configureStore({
    reducer: {
        deliveries: deliveriesReducer,
        paymentMethods: paymentMethodsReducer,
        order: persistReducer(orderPersistConfig, orderReducer),
        formState: formStateReducer,
    },
});

persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
