import classNames from 'classnames';

import { GLOBAL_CLASS_NAMES } from '../../constants';

import styles from './FormItem.module.css';

interface FormItemProps {
    errorMessage?: string;
    className?: string;
    children: JSX.Element;
}

const FormItem = ({ className, errorMessage, children }: FormItemProps): JSX.Element => {
    const containerClassName = classNames(styles.Container, className);

    return <div className={ containerClassName }>
        <div>
            { children }
        </div>
        { errorMessage && 
            <>
                <div className={ classNames(styles.ScrollTarget, GLOBAL_CLASS_NAMES.formItemWithError) } />
                <div className={ styles.Error }>
                    { errorMessage }
                </div> 
            </>
        }
    </div>;
};

export default FormItem;
