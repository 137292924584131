import { useState, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useSwipeable } from 'react-swipeable';
import classNames from 'classnames';

import Button from '../../ui/button';
import Icon from '../../ui/icon';
import Cart from '../cart/Cart';
import Summary from '../summary/Summary';
import { useAppSelector } from '../../hooks';
import { getProductsPrice } from '../../store/orderSlice';
import { formatPrice } from '../../utils/formats';

import styles from './CartSlider.module.css';

const SWIPE_OPEN_ICON = '/icons/swipe_open.svg';
const SWIPE_CLOSE_ICON = '/icons/swipe_close.svg';

const SWIPE_THRESHOLD = 10;

const CartSlider = (): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);

    const [ isOpen, setIsOpen ] = useState(false);
    
    const productsPrice = useAppSelector(getProductsPrice);

    const swipeHanlders = useSwipeable({
        onSwiped: (event) => {
            if (![ 'Up', 'Down' ].includes(event.dir)) {
                return;
            }

            if (event.dir === 'Down' && event.deltaY > SWIPE_THRESHOLD) {
                setIsOpen(false);
            } else if (event.dir === 'Up' && -event.deltaY > SWIPE_THRESHOLD) {
                setIsOpen(true);
            }
            
            document.body.style.overflow = '';
            ref.current && (ref.current.dataset.swipingDirection = '');
            requestAnimationFrame(() => {
                ref.current && (ref.current.style.transform = '');
            });
        },
        onSwiping: (e) => { 
            if (!ref.current || ![ 'Up', 'Down' ].includes(e.dir)) {
                return;
            }

            document.body.style.overflow = 'hidden';
            ref.current.dataset.swipingDirection = e.dir;
            if (isOpen) {
                ref.current.style.transform = `translateY(max(${e.deltaY}px, 0px))`;
            } else {
                ref.current.style.transform = (
                    `translateY(max(calc(100% - var(--slider-header-height) + ${e.deltaY}px), 0px))`
                );
            }
        },
    });

    const toggle = useCallback(() => setIsOpen(!isOpen), [ isOpen ]);

    const wrapperClassName = classNames({
        [ styles.Wrapper ]: true,
        [ styles.Opened ]: isOpen,
    });

    const refPassthrough = (element: HTMLDivElement) => {
        swipeHanlders.ref(element);
        
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref.current = element;
    };
    
    return ReactDOM.createPortal(
        <div className={ wrapperClassName }>
            <div 
                ref={ refPassthrough } 
                className={ styles.Container } 
            >
                <div>
                    <div className={ styles.SwitchStateButtonWrapper }>
                        <Button type="icon" onClick={ toggle }>
                            <Icon src={ isOpen ? SWIPE_CLOSE_ICON : SWIPE_OPEN_ICON } />
                        </Button>
                    </div>
                    <div className={ styles.ProductTitle }>
                        Товары  { formatPrice(productsPrice) } ₽
                        <div className={ styles.ProductTitleButton }>
                            <Button type="icon" onClick={ toggle }>
                                <Icon src="/icons/close.svg" />
                            </Button>
                        </div>
                    </div>
                </div>
                <div>
                    <Cart />
                    <Summary />
                </div>
            </div>
            <div className={ styles.Overlay } onClick={ toggle }/>
        </div>,
        document.body,
    );
};


export default CartSlider;
