import { 
    createSlice, 
    createAsyncThunk,
    PayloadAction,
} from '@reduxjs/toolkit';

import Cookies from 'js-cookie';

import { request } from '../api';
import {
    OrderState,
    CheckoutData,
    Address,
    PaymentMethod,
    Delivery,
    Pvz,
    Product,
    City,
    SelectedDeliveryOrDeliveryType,
} from './types';
import {
    CHECKOUT_ID,
} from '../constants';
import { toSnakeCase } from '../utils/data-preprocessors';
import type { AppThunk, RootState } from './index';
import { LoadingState } from '../types';
import { filterPaymentMethods, clearPaymentMethodsFilters } from './paymentMethodsSlice';
import { fetchDeliveries, getPvzList } from './deliveriesSlice';
import { IframeEventTypes, sendMessage } from '../messages';
import { clearError, setValid } from './formStateSlice';

const initialState: OrderState = {
    loadingState: 'not_started',

    personality: {
        fullName: '',
        
        phone: '',
        email: '',
    },

    phoneConfirmation: {
        isConfirmed: false,
        status: 'not_started',
    },

    products: [],

    city: {
        name: '',
        fias_id: null,
    },

    address: {
        raw: '',
        aoguid: null,
    },

    pvz: undefined,

    previouslySelectedAddressList: [],
    previouslySelectedPvzList: [],

    availableDeliveryMethodsList: [],

    lastUpdatedAt: undefined,
};

interface GetCartResponse {
    cart_info: {
        shop_cart_id?: string;
        products: Product[];
    },
    customer_info: {
        first_name: string;
        last_name: string;
        middle_name: string;
        phone: string;
        email: string;

        updated_at: string;
    },
    city:{
        raw: string;
        fias_id: string;
    },

    delivery_addresses: Address[],
    previously_selected_pvz_list: Pvz[],
    available_delivery_methods_list: string[],
}

const getPhoneIsConfirmed = (phone: string): boolean => {
    const cookiePhone = (Cookies.get('phone') || '').split('.')[0];
    return Boolean(phone && cookiePhone && phone === cookiePhone);
};

const getCheckoutState = (getCartResponse: GetCartResponse): CheckoutData => {
    const { phone } = getCartResponse.customer_info;
    const isConfirmed = getPhoneIsConfirmed(phone);

    return {
        personality: {
            fullName: [ getCartResponse.customer_info.last_name,
                getCartResponse.customer_info.first_name,
                getCartResponse.customer_info.middle_name
            ].filter(Boolean).join(' '),

            phone,
            email: getCartResponse.customer_info.email,
        },

        products: getCartResponse.cart_info.products,

        shop_cart_id: getCartResponse.cart_info.shop_cart_id,

        phoneConfirmation: {
            isConfirmed,
            status: isConfirmed ? 'done' : 'not_started',
        },

        city: {
            name: getCartResponse.city.raw,
            fias_id: getCartResponse.city.fias_id
        },

        previouslySelectedAddressList: getCartResponse.delivery_addresses,
        previouslySelectedPvzList: getCartResponse.previously_selected_pvz_list,

        availableDeliveryMethodsList: getCartResponse.available_delivery_methods_list,

        lastUpdatedAt: getCartResponse.customer_info.updated_at ? (
            new Date(getCartResponse.customer_info.updated_at).getTime()
        ) : undefined,
    };
};

export const fetchOrder = createAsyncThunk(
    'order/fetchOrder',
    async () => {
        const getCartResponse: GetCartResponse = await request(
            '/get_cart', 
            { query: { id: CHECKOUT_ID } }
        );
        return getCheckoutState(getCartResponse);
    },
);

export const selectDeliveryOrDeliveryType = createAsyncThunk<
    void, 
    SelectedDeliveryOrDeliveryType, 
    { state: RootState }
>(
    'order/selectDeliveryOrDeliveryType',
    async (selectedDeliveryOrDeliveryType, { dispatch, getState }) => {
        const delivery = selectedDeliveryOrDeliveryType.delivery || 
            getDeliveryBySelectedPvz(getState());

        dispatch(selectDeliveryOrDeliveryTypeAction(selectedDeliveryOrDeliveryType));
        dispatch(changeDeliveryAction(delivery));

        if (delivery) {
            dispatch(filterPaymentMethods(delivery.payments));
        } else {
            dispatch(clearPaymentMethodsFilters());
        }

        const state = getState();
        const selectedPaymentMethod = state.order.paymentMethod;
        if (delivery && (!selectedPaymentMethod || !(selectedPaymentMethod.id in delivery.payments))) {
            const { availablePaymentMethods } = state.paymentMethods;
            dispatch(changePaymentMethod(availablePaymentMethods[0]));
        }
    },
);

export const changePvz = createAsyncThunk<void, Pvz, { state: RootState }>(
    'order/changePvz',
    async (pvz, { dispatch, getState }) => {
        const { deliveries } = getState().deliveries;
        const delivery = deliveries.find(item => item.id === pvz.delivery_method_id) as Delivery;

        await dispatch(selectDeliveryOrDeliveryType({ delivery, deliveryType: undefined }));

        dispatch(changePvzAction(pvz));
        dispatch(markPvzAsPreviouslySelected(pvz));

        dispatch(setValid([ 'delivery', 'pvz' ]));
    },
);

export const changePvzById = createAsyncThunk<void, string, { state: RootState }>(
    'order/changePvzById',
    async (pvzId, { dispatch, getState }) => {
        const state = getState();

        const pvzList = getPvzList(state);
        const _pvz = pvzList.find(item => item.id === pvzId);
        if (!_pvz) {
            throw Error(`Cant find pvz ${pvzId}.`);
        }
        const pvz = {
            ..._pvz,
            'city_name': state.order.city.name,
            'city_fias_id': state.order.city.fias_id as string
        };
        await dispatch(changePvz(pvz));
    },
);

export const setPvzFromAvailable = (fiasId: string): AppThunk => (dispatch, getState) => {
    const state = getState();
    const previouslySelectedPvzList = getPreviouslySelectedPvzList(state);
    const availablePvz = previouslySelectedPvzList.find(pvz => pvz.city_fias_id === fiasId);
    if (availablePvz) {
        dispatch(changePvz(availablePvz));
    } else {
        dispatch(changePvzAction());
    }
};

export const setDeliveryAddressFromAvailable = (fiasId: string): AppThunk => (dispatch, getState) => {
    const state = getState();
    const availableDeliveryAddress = state.order.previouslySelectedAddressList.find(
        address => address.city_fias_id === fiasId
    );
    if (availableDeliveryAddress) {
        dispatch(changeAddress(availableDeliveryAddress));
        dispatch(setValid([ 'delivery', 'address' ]));
    } else {
        dispatch(changeAddress({ 'raw': '', 'aoguid': null }));
    }

};

export const manualChangeCity = createAsyncThunk<
    void, 
    { cityName: string, cityFiasId: string },
    { state: RootState }
    >(
        'order/manualChangeCity',
        async ({ cityName, cityFiasId }, { dispatch }) => {
            await dispatch(changeCity({ cityName, cityFiasId }));

            dispatch(setPvzFromAvailable(cityFiasId));
            dispatch(setDeliveryAddressFromAvailable(cityFiasId));
        }
    );

const changeCity = createAsyncThunk<void, { cityName: string, cityFiasId: string }, { state: RootState }>(
    'order/changeCity',
    async ({ cityName, cityFiasId }, { dispatch }) => {
        dispatch(changeCityAction({
            name: cityName,
            fias_id: cityFiasId,
        }));
    
        await dispatch(fetchDeliveries());
    }
);

export const setPreviouslySelectedDeliveryAddress = createAsyncThunk<void, Address, { state: RootState }>(
    'order/setPreviouslySelectedDeliveryAddress',
    async (address, { dispatch, getState }) => {
        const state = getState();

        if (address.city_fias_id !== state.order.city.fias_id) {
            const [ cityName, cityFiasId ] = [ address.city_name, address.city_fias_id ];
            await dispatch(changeCity({ cityName, cityFiasId }));

            const pvz = state.order.previouslySelectedPvzList.find(pvz => pvz.city_fias_id === address.city_fias_id);
            dispatch(changePvzAction(pvz));
            pvz && dispatch(setValid([ 'delivery', 'pvz' ]));
        }
        dispatch(changeAddress({
            raw: address.raw,
            aoguid: address.aoguid,
        }));
        dispatch(setValid([ 'delivery', 'address' ]));
    }
);

export const setPreviouslySelectedPvz = createAsyncThunk<void, Pvz, { state: RootState }>(
    'order/setPreviouslySelectedPvz',
    async (pvz, { dispatch, getState }) => {
        const state = getState();

        if (pvz.city_fias_id !== state.order.city.fias_id) {
            const [ cityName, cityFiasId ] = [ pvz.city_name, pvz.city_fias_id ];
            await dispatch(changeCity({ cityName, cityFiasId }));

            dispatch(setDeliveryAddressFromAvailable(pvz.city_fias_id as string));
        }
        dispatch(changePvz(pvz));
    }
);

const refreshUpdateAtTime = (state: OrderState) => {
    state.lastUpdatedAt = Date.now();
};

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        updateFullName: (state: OrderState, { payload }: PayloadAction<{'fullName': string}>) => {
            state.personality.fullName = payload.fullName;

            refreshUpdateAtTime(state);
        },

        updatePhone: (state: OrderState, { payload }: PayloadAction<string>) => {
            state.personality.phone = payload;

            const { phoneConfirmation } = state;

            phoneConfirmation.isConfirmed = getPhoneIsConfirmed(payload);
            if (phoneConfirmation.isConfirmed) {
                phoneConfirmation.status = 'done';
            } else {
                phoneConfirmation.status = 'not_started';
            }
            
            // Тут надо подумать, но, кажется, есть смысл не обновлять время
            // изменения данных при изменении номера, кейс такой:
            // 1) Оформляем заказ с компа
            // 2) Оформляем заказ с телефона — при его вводе у меня просталяется state.lastUpdatedAt => Date.now()
            // 3) После подтверждения номера нам приходят данные 
            // в которых lastUpdateAt будет в любом случае < Date.now() => данные мои не подтянутся
        }, 

        startConfirmation: (state: OrderState) => {
            state.phoneConfirmation.status = 'in_progress';
        },

        markConfirmed: (state: OrderState) => {
            state.phoneConfirmation.status = 'done';
        },
        
        updateEmail: (state: OrderState, { payload }: PayloadAction<string>) => {
            state.personality.email = payload;
            
            refreshUpdateAtTime(state);
        }, 

        selectDeliveryOrDeliveryType: (
            state: OrderState, 
            { payload }: PayloadAction<SelectedDeliveryOrDeliveryType>
        ) => {
            state.selectedDeliveryOrDeliveryType = payload;
        },
        
        changeDelivery: (state: OrderState, { payload }: PayloadAction<Delivery | undefined>) => {
            state.delivery = payload;
        }, 

        markPvzAsPreviouslySelected: (state: OrderState, { payload }: PayloadAction<Pvz>) => {
            if (!state.previouslySelectedPvzList.find(item => item.id === payload.id)) {
                state.previouslySelectedPvzList.unshift(payload);
            }
        },
        
        changePvz: (state: OrderState, { payload }: PayloadAction<Pvz | undefined>) => {
            state.pvz = payload;
            
            refreshUpdateAtTime(state);
        },

        changeCity: (state: OrderState, { payload } : PayloadAction<City>) => {
            state.city.name = payload.name;
            state.city.fias_id = payload.fias_id;

            refreshUpdateAtTime(state);
        },

        changeAddress: (state: OrderState, { payload }: PayloadAction<OrderState['address']>) => {
            state.address = payload;
            
            refreshUpdateAtTime(state);
        },
        
        changePaymentMethod: (state: OrderState, { payload }: PayloadAction<PaymentMethod>) => {
            state.paymentMethod = payload;
        }, 

        incrementProductQuantity: (state: OrderState, { payload }: PayloadAction<Product>) => {
            for (const product of state.products) {
                if (product.id === payload.id) {
                    if (product.quantity < product.max_quantity) {
                        product.quantity++;
                        
                        sendMessage(IframeEventTypes.UPDATE_QUANTITY, {
                            id: product.id,
                            quantity: product.quantity,
                        });
                    }
                    break;
                }
            }
        },
        
        decrementProductQuantity: (state: OrderState, { payload }: PayloadAction<Product>) => {
            for (const product of state.products) {
                if (product.id === payload.id) {
                    if (product.quantity > product.min_quantity) {
                        product.quantity--;

                        sendMessage(IframeEventTypes.UPDATE_QUANTITY, {
                            id: product.id,
                            quantity: product.quantity,
                        });
                    }
                    break;
                }
            }
        },

        deleteProduct: (state: OrderState, { payload }: PayloadAction<Product>) => {
            const index = state.products.findIndex(item => item.id === payload.id);
            if (index !== -1){
                const productId = state.products[index].id;

                state.products.splice(index, 1);

                sendMessage(IframeEventTypes.UPDATE_QUANTITY, {
                    id: productId,
                    quantity: 0,
                });
            }
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchOrder.pending, (state) => {
                // To prevent interface jumping
                if (state.loadingState !== 'done') {
                    state.loadingState = 'in_progress';
                }
            })
            .addCase(fetchOrder.fulfilled, (state, { payload }: PayloadAction<CheckoutData>) => {
                state.loadingState = 'done';
                
                const preferLocalState = state.lastUpdatedAt && (
                    !payload.lastUpdatedAt || state.lastUpdatedAt >= payload.lastUpdatedAt
                );

                state.previouslySelectedAddressList = payload.previouslySelectedAddressList;
                state.previouslySelectedPvzList = payload.previouslySelectedPvzList;
                state.availableDeliveryMethodsList = payload.availableDeliveryMethodsList;

                if (!preferLocalState) {
                    state.personality = payload.personality;

                    state.city = payload.city;
                    state.lastUpdatedAt = payload.lastUpdatedAt;
                }

                if (!state.pvz) {
                    const pvz = state.previouslySelectedPvzList.find(pvz => (
                        pvz.city_fias_id === state.city.fias_id &&
                        state.availableDeliveryMethodsList.includes(pvz.delivery_method_id)
                    ));
                    if (pvz) {
                        state.pvz = pvz;
                    }
                } else if (!state.previouslySelectedPvzList.find(item => item.id === state.pvz?.id)) {
                    state.previouslySelectedPvzList.unshift(state.pvz);
                }

                if (!state.address) {
                    const address = state.previouslySelectedAddressList.find(address => (
                        address.city_fias_id === state.city.fias_id
                    ));
                    if (address) {
                        state.address = address;
                    }
                }
                state.products = payload.products;

                state.phoneConfirmation = payload.phoneConfirmation;

                state.shop_cart_id = payload.shop_cart_id;

            });
    },
});

export const {
    updateFullName,
    updatePhone,
    startConfirmation,
    markConfirmed,
    updateEmail,
    changeDelivery: changeDeliveryAction,
    selectDeliveryOrDeliveryType: selectDeliveryOrDeliveryTypeAction,
    markPvzAsPreviouslySelected,
    changePvz: changePvzAction,
    changeAddress,
    changePaymentMethod,
    incrementProductQuantity,
    decrementProductQuantity,
    changeCity: changeCityAction,
    deleteProduct,
} = orderSlice.actions;

export const getOrderLoadingState = (store: RootState): LoadingState => store.order.loadingState;

export const getPersonality = (store: RootState): RootState['order']['personality'] => store.order.personality;

export const getConfirmationState = (store: RootState): RootState['order']['phoneConfirmation'] => 
    store.order.phoneConfirmation;

export const getProducts = (store: RootState): RootState['order']['products'] => store.order.products;

export const getFullName = (store: RootState): string => store.order.personality.fullName;

export const getSelectedDeliveryOrDeliveryType = (
    store: RootState
): RootState['order']['selectedDeliveryOrDeliveryType'] => {
    return store.order.selectedDeliveryOrDeliveryType;
};

export const getSelectedPaymentMethod = (store: RootState): RootState['order']['paymentMethod'] => 
    store.order.paymentMethod;

export const getProductsPrice = (store: RootState): number => (
    store.order.products.reduce((previous, current) => previous + current.price * current.quantity, 0)
);

export const getDiscountAmount = (store: RootState): number => {
    const oldPriceSum = (
        store.order.products.reduce(
            (previous, current) => previous + (current.old_price || current.price) * current.quantity,
            0
        )
    );
    return oldPriceSum - getProductsPrice(store);
};

export const getDeliveryPrice = (store: RootState): number | undefined => store.order.delivery?.price;

export const getDeliveryDays = (store: RootState): number | undefined => store.order.delivery?.days;

export const getDeliveryType = (store: RootState): Delivery['type'] | undefined => store.order.delivery?.type;

export const getDelivery = (store: RootState): Delivery | undefined => store.order.delivery;

const getDeliveryBySelectedPvz = (store: RootState): Delivery | undefined => {
    const { pvz } = store.order;
    if (!pvz) {
        return;
    }

    const { deliveries } = store.deliveries;
    return deliveries.find(item => item.id === pvz.delivery_method_id);
};

export const getDeliveryAddress = (store: RootState): RootState['order']['address'] => store.order.address;

export const getPvz = (store: RootState): RootState['order']['pvz'] => store.order.pvz;

export const getCity = (store: RootState): City => store.order.city;

export const getPreviouslySelectedAddressList = (store: RootState) => store.order.previouslySelectedAddressList;

export const getPreviouslySelectedPvzList = (store: RootState) => {
    return store.order.previouslySelectedPvzList.filter(pvz => {
        return store.order.availableDeliveryMethodsList.includes(pvz.delivery_method_id);
    });
};

export const isLatestProductGetter = (store: RootState, currentProduct: Product) => {
    for (const product of store.order.products){
        if (product.id === currentProduct.id) {
            return product.quantity === product.min_quantity;
        }
    }
};

export const _splitFullName = (fullName: string): { lastName: string; firstName: string; middleName: string; } => {
    const [ lastName, firstName, ...rest ] = fullName.split(' ').filter(Boolean);
    const middleName = rest.join(' ');

    return {
        lastName: lastName || '',
        firstName: firstName || '',
        middleName: middleName || ''
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOrder = (store: RootState): any => {
    const personality = getPersonality(store);
    const city = getCity(store);

    return toSnakeCase({
        customer_info: {
            ..._splitFullName(personality.fullName),
            ...personality,
        },
        cart_info: {
            products: getProducts(store),
            // TODO: Эту херь не нужно пробрасывать на фронт ваапще говоря
            shop_cart_id: store.order.shop_cart_id,
        },
        delivery: {
            ...getDelivery(store),
        },
        payment: getSelectedPaymentMethod(store),
        city: {
            ...city,
            raw: city.name,
        },
        pvz: store.order.pvz,
        delivery_address: store.order.address,
    });
};

export const reducer = orderSlice.reducer;
