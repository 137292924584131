import { useCallback, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import {
    updateFullName,
    updatePhone,
    getConfirmationState,
    startConfirmation,
    markConfirmed,
    fetchOrder,
} from '../../store/orderSlice';
import Input, { PhoneInput } from '../../ui/input/index';
import Icon from '../../ui/icon';
import Button from '../../ui/button';
import { useAppSelector } from '../../hooks';
import { getPersonality, getFullName } from '../../store/orderSlice';
import AutocompleteInput from '../../ui/input/autocomplete-input';
import { request } from '../../api';
import { IS_MOBILE } from '../../constants';
import { Item } from '../../ui/input/autocomplete-input';
import FormItem from '../../ui/form/FormItem';
import {
    clearError,
    getValidation,
    setError,
    validateFullNameThunk,
    validatePhoneThunk,
    ValidationKeys,
} from '../../store/formStateSlice';

import styles from './Personality.module.css';

interface FullNameItem {
    last_name: string,
    first_name: string,
    middle_name: string
}


function getFullNameSuggestion(value: string) {
    return request(
        '/get_fio_autocomplete',
        {
            query: {
                query: value
            }
        }
    ).then(result => {
        return result['suggestions'].map((item: FullNameItem, id: number) => {
            const fullName = [
                item['last_name'],
                item['first_name'],
                item['middle_name'],
            ].filter(Boolean).join(' ');

            return {
                id: id,
                label: fullName
            };
        });
    });
}

const Confirmator = (): JSX.Element => {
    const { phone } = useAppSelector(getPersonality);
    const confirmation = useAppSelector(getConfirmationState);
    const validation = useAppSelector(getValidation);
    const dispatch = useDispatch();

    const { smsCode: { errorMessage: smsCodeError } } = validation.personality;

    const onStart = useCallback(async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { payload: isValid } = await dispatch(validatePhoneThunk());
        if (isValid) {
            request('/send_sms_code', {
                body: {
                    phone,
                },
            }).then(() => {
                dispatch(startConfirmation());
            });
        }
    }, [ phone ]);
    
    const onInputCode = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const currentTarget = event.currentTarget;
        const code = event.currentTarget.value;
        if (code.length === 4) {
            request('/check_sms_code', {
                body: {
                    phone, 
                    code,
                },
            }).then(({ status }) => {
                if (status === 'ok') {
                    dispatch(markConfirmed());
                    dispatch(fetchOrder());
                } else {
                    const error: { key: ValidationKeys, value: string } = {
                        key: [ 'personality', 'smsCode' ],
                        value: 'Неверный код'
                    };
                    dispatch(setError(error));
                    currentTarget.value = '';
                }
            });
        }
    }, [ phone ]);

    if (confirmation.status === 'not_started') {
        return (
            <div className={ styles.ConfirmatorWrapper }>
                <Button 
                    type="normal-outline"
                    onClick={ onStart }
                >
                    <div>Подтвердить номер</div>
                </Button>
            </div>
        );
    }

    if (confirmation.status === 'in_progress') {
        return (
            <FormItem
                className={ styles.PhoneNumberInputContainer }
                errorMessage={ smsCodeError }
            >
                <Input 
                    label="Введите код из SMS"
                    hasError={ Boolean(smsCodeError) }
                    onInput={ onInputCode }
                />
            </FormItem>
        );
    }

    return (
        <div className={ classNames(styles.ConfirmatorWrapper, styles.ConfirmedStatus) }>
            {/* @ts-ignore */}
            <Icon src="/icons/checkmark.svg" className={ styles.CheckIcon } />
            Номер подтверждён
        </div>  
    );
};

const Personality = (): JSX.Element => {
    const { phone } = useAppSelector(getPersonality);
    const fullName = useAppSelector(getFullName);
    const validation = useAppSelector(getValidation);
    const {
        phone: { errorMessage: phoneError },
        fullName: { errorMessage: fullNameError }
    } = validation.personality;

    const dispatch = useDispatch();

    const onChangeFullName = useCallback((item: Item) => {
        dispatch(updateFullName({ fullName: item.label }));
    }, [ dispatch ]);

    const onInputFullName = useCallback((value: string) => {
        dispatch(updateFullName({ fullName: value }));
        if (value) {
            dispatch(clearError([ 'personality', 'fullName' ]));
        }
    }, [ dispatch ]);

    const onChangePhone = useCallback((value: string) => {
        dispatch(updatePhone(value || ''));
        if (value) {
            dispatch(clearError([ 'personality', 'phone' ]));
        }
    }, [ dispatch ]);

    const onBlurPhone = useCallback(() => {
        dispatch(validatePhoneThunk());
    }, []);

    const onBlurFullName = useCallback(() => {
        dispatch(validateFullNameThunk());
    }, []);

    const classNameWrapper = classNames(styles.DataContainer, { [styles.IsMobile]: IS_MOBILE });

    return (
        <div>
            <div className={ classNameWrapper }>
                <div className={ styles.Title }>
                    Ваши данные
                </div>
                <div>
                    <div className={ styles.PhoneNumberContainer }>
                        <FormItem
                            className={ styles.PhoneNumberInputContainer }
                            errorMessage={ validation.personality.phone.errorMessage }
                        >
                            <PhoneInput
                                label="Телефон*"
                                value={ phone }
                                onChange={ onChangePhone }
                                onBlur={ onBlurPhone }
                                hasError={ Boolean(phoneError) }
                            />
                        </FormItem>
                        <div className={ styles.PhoneNumberButtonContainer }>
                            <Confirmator />
                        </div>
                    </div>
                    <FormItem
                        className={ styles.FullNameInputContainer }
                        errorMessage={ validation.personality.fullName.errorMessage }
                    >
                        <AutocompleteInput
                            getItems={ getFullNameSuggestion }
                            label="Фамилия и имя*"
                            value={ fullName }
                            onChange={ onChangeFullName }
                            onInput={ onInputFullName }
                            onBlur={ onBlurFullName }
                            hasError={ Boolean(fullNameError) }
                        />
                    </FormItem>
                </div>
            </div>
        </div>);
};

export default Personality;
