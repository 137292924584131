import { useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import { useOutsideClick } from '../../hooks/click-outside';
import { IS_MOBILE } from '../../constants';

import styles from './Modal.module.css';

interface ModalProps {
    visible: boolean;
    children: React.ReactNode;
    onClose: () => void;
}

const Modal = ({ visible, children, onClose }: ModalProps): JSX.Element => {
    const className = classNames({
        [ styles.Root ]: true,
        [ styles.IsMobile ]: IS_MOBILE,
        [ styles.Hidden ]: !visible,
    });

    const contentRef = useRef(null);
    useOutsideClick(contentRef, onClose);

    return ReactDOM.createPortal(
        <div role="dialog" className={ className }>
            <div className={ styles.Overlay }>
                <div className={ styles.Container }>
                    <div className={ styles.Content } ref={ contentRef }>
                        { children }
                    </div>
                </div>
            </div>
        </div>,
        document.body,
    );
};

export default Modal;
