import style from './icon.module.css';
import classNames from 'classnames';

import { IS_MOBILE } from '../constants';


interface IconProps {
    src: string;
    className?: string;
}

function Icon({ src, className }: IconProps): JSX.Element {
    const classNameIcon = classNames(
        className,
        {
            [style.DesktopIcon]: !IS_MOBILE,
            [style.MobileIcon]: IS_MOBILE
        }
    );
    return <img className={ classNameIcon } src={ src }/>;
}

export default Icon;
