import { 
    createSlice, 
    createAsyncThunk,
    PayloadAction,
} from '@reduxjs/toolkit';

import { deliveriesRequest } from '../api/deliveries';
import type { 
    PaymentMethodsState,
    PaymentMethodsData,
    AvailablePaymentMethodsMapping,
} from './types';
import type { RootState } from './index';
import { changePaymentMethod } from './orderSlice';
import { LoadingState } from '../types';

const initialState: PaymentMethodsState = {
    loadingState: 'not_started',

    paymentMethods: [],
    availablePaymentMethods: [],
};

interface GetPaymentTypesItem {
    name: string;
    code: string;
}

interface GetPaymentTypesResponse {
    delivery_payment_types: Array<GetPaymentTypesItem>;
}

export const fetchPaymentMethods = createAsyncThunk(
    'paymentMethods/fetchPaymentMethods',
    async (_, { dispatch }) => {
        const response = await deliveriesRequest<GetPaymentTypesResponse>(
            '/api/v1/get_delivery_payment_types'
        );

        const paymentMethods = response.delivery_payment_types.map((item) => ({
            id: item.code,
            name: item.name,
        }));

        const paymentMethodsData: PaymentMethodsData = {
            paymentMethods,
            availablePaymentMethods: paymentMethods,
        };

        dispatch(setPaymentMethods(paymentMethodsData));
        
        const { availablePaymentMethods } = paymentMethodsData;
        if (availablePaymentMethods.length) {
            dispatch(changePaymentMethod(availablePaymentMethods[0]));
        }
    },
);

export const paymentMethodsSlice = createSlice({
    name: 'paymentMethods',
    initialState,
    reducers: {
        setPaymentMethods: (state: PaymentMethodsState, { payload }: PayloadAction<PaymentMethodsData>) => {
            state.paymentMethods = payload.paymentMethods;

        },

        filterPaymentMethods: (
            state: PaymentMethodsState, 
            { payload }: PayloadAction<AvailablePaymentMethodsMapping>
        ) => {
            state.availablePaymentMethods = state.paymentMethods.filter(item => payload[ item.id ]);
        },
        
        clearPaymentMethodsFilters: (
            state: PaymentMethodsState, 
        ) => {
            state.availablePaymentMethods = state.paymentMethods;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPaymentMethods.pending, (state) => {
                state.loadingState = 'in_progress';
            })
            .addCase(fetchPaymentMethods.fulfilled, (state) => {
                state.loadingState = 'done';
            });
    },
});

export const { 
    setPaymentMethods, 
    filterPaymentMethods,
    clearPaymentMethodsFilters,
} = paymentMethodsSlice.actions;

export const reducer = paymentMethodsSlice.reducer;

export const getPaymentMethodsLoadingState = (store: RootState): LoadingState => store.paymentMethods.loadingState;

export const getPaymentMethods = (store: RootState): RootState['paymentMethods']['paymentMethods'] => 
    store.paymentMethods.availablePaymentMethods;
