import React, { ChangeEvent, useContext, useMemo } from 'react';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';

import styles from './radio.module.css';

type ChangeHandler = (event: ChangeEvent<HTMLInputElement>) => void;
interface RadioGroupContextValue {
    groupName?: string;
    value?: string;
    onChange: ChangeHandler;
}

const RadioGroupContext = React.createContext<RadioGroupContextValue>({
    onChange: () => undefined,
});

interface RadioProps {
    className?: string;
    value: string;
    children: React.ReactNode;
}

function Radio({ value, children }: RadioProps): JSX.Element {
    const context = useContext(RadioGroupContext);

    const checked = value === context.value;

    return <label className={ classNames(styles.RadioLabel, { [styles.Checked]: checked }) }>
        <input
            className={ styles.RadioInput }
            type="radio"
            name={ context.groupName }
            value={ value }
            checked={ checked }
            onChange={ context.onChange }
        />
        <span className={ styles.Checkmark } />
        { children }
    </label>;
}

interface RadioGroupProps {
    className?: string;
    onChange: ChangeHandler;
    value?: string;
    children: React.ReactNode;
}

function RadioGroup({ value, children, onChange }: RadioGroupProps) {
    const groupName = useMemo(() => uniqueId('radio-group-'), []);

    return <RadioGroupContext.Provider value={{ groupName, value, onChange }}>
        <div className={ styles.RadioGroup }>
            { children }
        </div>
    </RadioGroupContext.Provider>;
}

Radio.Group = RadioGroup;

export default Radio;
