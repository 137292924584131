import classNames from 'classnames';

import { IS_MOBILE } from '../../constants';

import styles from './Summary.module.css';
import Button from '../../ui/button';
import Icon from '../../ui/icon';
import { useAppSelector } from '../../hooks';
import { getDeliveryPrice, getDiscountAmount, getProductsPrice } from '../../store/orderSlice';
import { formatPrice } from '../../utils/formats';

const Summary = (): JSX.Element => {
    const productsPrice = useAppSelector(getProductsPrice);
    const discount = useAppSelector(getDiscountAmount);
    const deliveryPrice = useAppSelector(getDeliveryPrice);

    const classNameWrapper = classNames(styles.Wrapper, { [styles.IsMobile]: IS_MOBILE });

    return (
        <div className={ classNameWrapper }>
            <div className={ styles.SummaryContentWrapper }>
                <div className={ styles.TotalWrapper }>
                    <div className={ styles.Total }>
                        <div>
                            Итого:
                        </div>
                        <div className={ styles.TotalSum }>
                            { formatPrice(productsPrice + (deliveryPrice || 0)) } ₽
                        </div>
                    </div>
                </div>
                <div className={ styles.CostWrapper }>
                    <div className={ styles.CostContainer }>
                        <div className={ styles.Cost }>
                            <div>Сумма по товарам</div>
                            <div className={ styles.Price }>{ formatPrice(productsPrice) } ₽</div>
                        </div>
                        { discount > 0 &&
                            <div className={ classNames(styles.SaleContainer, styles.Cost) }>
                                <div>Скидка</div>
                                <div className={ styles.Price }> -{ formatPrice(discount) } ₽</div>
                            </div>
                        }
                        <div className={ styles.Cost }>
                            <div>Стоимость доставки</div>
                            <div className={ styles.Price }>
                                { 
                                    deliveryPrice ? formatPrice(deliveryPrice) + ' ₽' : (
                                        <div title="Выбирете пункт выдачи">
                                            <Icon src="/icons/question_mark.svg" />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Summary;
