import { CLIENT_TOKEN } from '../constants';

const DELIVERIES_HOST = process.env.REACT_APP_DELIVERIES_HOST;

type DeliveriesApiMethods = (
    '/widget/pvzs/get/' |
    '/widget/pvzs/info/' |

    '/api/v1/get_delivery_methods_by_city' |
    '/api/v1/get_delivery_payment_types' |

    '/fias/autocomplete/'
);

type BodyRecord = Record<string, string | number | null>;
type Body = Record<string, string | number | null | BodyRecord | Array<BodyRecord>>;
type Query = Record<string, string>;

export class HttpError extends Error {}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deliveriesRequest = async <RetType = any>(
    method: DeliveriesApiMethods, 
    { body, query }: { body?: Body, query?: Query } = {}
): Promise<RetType> => {
    const httpMethod = body ? 'POST' : 'GET';

    let url = DELIVERIES_HOST + method;
    if (query) {
        url += '?' + new URLSearchParams(query).toString();
    }

    const response = await fetch(
        url,
        { 
            method: httpMethod,
            body: body ? JSON.stringify(body) : undefined,
            headers: {
                'X-Token': CLIENT_TOKEN,
            }
        }
    );

    if (!response.ok) {
        throw new HttpError(response.statusText);
    }

    return response.json();
};
