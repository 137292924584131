import React, { useState } from 'react';
import classNames from 'classnames';

import Button from '../../ui/button';
import Icon from '../../ui/icon';
import { IS_MOBILE } from '../../constants';
import { useAction } from '../../hooks';
import { Product } from '../../store/types';
import {
    incrementProductQuantity,
    decrementProductQuantity,
    isLatestProductGetter,
} from '../../store/orderSlice';
import store from '../../store';
import DeleteProductModal from './DeleteProductModal';

import styles from './ProductQuantity.module.css';


interface ProductQuantityProps {
    product: Product;
}

const ProductQuantity = ({ product }: ProductQuantityProps): JSX.Element => {
    const increment = useAction(incrementProductQuantity(product));
    const decrement = () => {
        const isLatest = isLatestProductGetter(store.getState(), product);
        if (!isLatest) {
            _decrement();
        } else {
            setDeletionModalVisible(true);
        }
    };
    const _decrement = useAction(decrementProductQuantity(product));
    const [ deletionModalVisible, setDeletionModalVisible ] = useState<boolean>(false);

    const onClose = () => {
        setDeletionModalVisible(false);
    };
    
    const classNameWrapper = classNames(styles.QuantityWrapper, { [styles.IsMobile]: IS_MOBILE });

    return (
        <div className={ classNameWrapper }>
            <div className={ styles.QuantityButtonMinus }>
                <Button type="icon" onClick={ decrement }>
                    <Icon src="/icons/minus.svg" />
                </Button>
            </div>
            <div className={ styles.QuantityText }>
                { product.quantity }
            </div>
            <div className={ styles.QuantityButtonPlus }>
                <Button type="icon" onClick={ increment }>
                    <Icon src="/icons/plus.svg" />
                </Button>
            </div>
            <DeleteProductModal
                visible={ deletionModalVisible }
                onClose={ onClose }
                product={ product }
            />
        </div>
    );
};

export default ProductQuantity;
