import { ReactNode } from 'react';
import classNames from 'classnames';

import { IS_MOBILE } from '../constants';

import style from './button.module.css';

type ButtonType = (
    'normal-outline' |
    'normal' |
    'accent' |
    'icon'
);

interface ButtonProps {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    type?: ButtonType;
    children: ReactNode;
    isLoading?: boolean;
    size?: string;
    hasError?: boolean;
}

function Button({ 
    type = 'normal', 
    isLoading = false,
    children, 
    size, 
    hasError = false,
    ...rest
}: ButtonProps): JSX.Element {
    const className = classNames({
        [ style.Button ]: true,
        [ style.NormalType ]: type === 'normal',
        [ style.OutlineType ]: type === 'normal-outline',
        [ style.IconType ]: type === 'icon',
        [ style.AccentType ]: type === 'accent',
        [ style.IsMobile ]: IS_MOBILE,
        [ style.IsLoading ]: isLoading,
        [ style.SizeXS ]: size === 'xs',
        [ style.Error ]: hasError,
    });

    return (
        <button 
            { ...rest } 
            className={ className }
        >
            <span className={ style.Content }>
                { children }
            </span>
        </button>
    );
}

export default Button;
