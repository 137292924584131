import { range } from '../../utils/range';

import styles from './Skeleton.module.css';

interface SkeletonProps {
    rows: number;
}

const Skeleton = ({ rows }: SkeletonProps): JSX.Element => {
    return (
        <div>
            { range(rows).map(index => (
                <div 
                    key={ index } 
                    className={ styles.ItemWrapper }
                >
                    <div 
                        className={ styles.Item }
                    />
                </div>
            )) }
        </div>
    );
};

export default Skeleton;
