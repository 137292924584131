import snakeCase from 'lodash/snakeCase';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toSnakeCase = (obj: Record<string, any>): Record<string, any> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // TODO: tests
    if (!obj) {
        return obj;
    }
    return Object.keys(obj).reduce((res: any, key: string) => {
        let value = obj[key];
        if (Array.isArray(value)) {
            value = value.map(toSnakeCase);
        } else if (typeof value === 'object') {
            value = toSnakeCase(value);
        }
        // TODO: fix it
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        res[ snakeCase(key) ] = value;
        return res;
    }, {});
};
