/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/ban-ts-comment */
import { useEffect } from 'react';
import classNames from 'classnames';

import { IS_MOBILE } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { deliveriesRequest } from '../../api/deliveries';
import { 
    changePvzById,
    getCity,
} from '../../store/orderSlice';
import {
    getDeliveries,
    fetchPvzList,
} from '../../store/deliveriesSlice';
import {
    setValid,
} from '../../store/formStateSlice';

import styles from './Map.module.css';
// It is small hack — DO NOT repeat it at home!
import buttonStyles from '../../ui/button.module.css';

let myMap: any;

// @ts-ignore
window.mapsHandlers = {
    selectPvz() {
        console.warn('selectPvz is not implemented!');
    }
};

const setObjectProperties = (objectManager: any, object: any, pvz: any) => {
    if (pvz) {
        let contentBody = `
            <p>
                <button type="button" 
                    style="width: 200px"
                    class="${ buttonStyles.Button } ${ buttonStyles.NormalType }" 
                    data-pvz-id="${pvz.pvz_id}" 
                    data-delivery-id="${pvz.delivery_method_id}" 
                    onclick="mapsHandlers.selectPvz.bind(this)(event)"
                >Выбрать</button>
            </p>
            <p> ${pvz.pvz_type} </p>
            <p> График работы: ${pvz.schedule} </p>
            ${getPhonesInfo(pvz.phones)}
        `;

        if (pvz.address_description) {
            contentBody += `<p>${pvz.address_description}</p>`;
        }

        object.properties.balloonContentBody = contentBody;

        object.properties.balloonContentFooter = pvz.address;

        object.properties.loaded = true;
    } else {
        object.properties.balloonContentHeader = 'Ошибка';
        object.properties.balloonContentBody = 'Информация не найдена';
    }

    // @ts-ignore
    objectManager.objects.balloon.setData(object);
};

// @ts-ignore
const getPhonesInfo = (phones) => {
    if (!phones || !phones.length) return '';

    const suff = 'Телефон' + (phones.length > 1 ? 'ы' : ''); 
    const phone_links = [];
    for (const phone of phones) {
        phone_links.push(`<a href='tel:${phone}'>${phone}</a>`);
    }
    return `<p> ${suff}: ${phone_links.join(', ')} </p>`;
};

// @ts-ignore
const getDays = value => {
    if (value === 0) return 'сегодня';
    let suff = 'дней'; 
    const rem = value % 10;
    if (rem === 1 && (value < 11 || value > 15)) suff = 'день';
    else if (rem >= 2 && rem <= 4) suff = 'дня';

    return `${value} ${suff}`;
};

// @ts-ignore
const getContentHeader = pvz => {
    let price = `${pvz.delivery_price} ₽`;
    if (!pvz.delivery_price) price = 'бесплатно';

    const daysAndPrice = [ price ];  
    if (pvz.delivery_days !== null) {
        daysAndPrice.splice(0, 0, getDays(pvz.delivery_days));
    }

    return `${pvz.delivery_method_name} ${daysAndPrice.join(', ')}`;
};

// TODO: lazy load maps..
const Map = ({ onClose }: { onClose: () => void }): JSX.Element => {
    const dispatch = useAppDispatch();

    const deliveries = useAppSelector(getDeliveries);
    const city = useAppSelector(getCity);

    useEffect(() => {
        // @ts-ignore
        window.mapsHandlers.selectPvz = function() {
            onClose();

            const { pvzId } = this.dataset as { pvzId: string };
            dispatch(changePvzById(pvzId));
            dispatch(setValid([ 'delivery', 'pvz' ]));
        };
        // dispatch()
    }, [ onClose, deliveries ]);

    useEffect(() => {
        dispatch(fetchPvzList()).then(({ payload: pvzs }) => {
            // @ts-ignore
            const objectManager = new ymaps.ObjectManager({
                // Чтобы метки начали кластеризоваться, выставляем опцию.
                clusterize: true,
                // ObjectManager принимает те же опции, что и кластеризатор.
                /**
                 * Через кластеризатор можно указать только стили кластеров,
                 * стили для меток нужно назначать каждой метке отдельно.
                 * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/option.presetStorage.xml
                 */

                // preset: 'islands#grayClusterIcons',

                // clusterLayout: 'default#pieChart',
                // layout: 'default#pieChart',
                // layout: sbYmaps.layout.PieChart,
                // clusterLayout: sbYmaps.layout.PieChart,

                // preset: 'default#pieChart',
                clusterIconLayout: 'default#pieChart',

                clusterIconPieChartRadius: 25,
                clusterIconPieChartCoreRadius: 15,
                /**
                 * Ставим true, если хотим кластеризовать только точки с одинаковыми координатами.
                 */
                groupByCoordinates: false,
                /**
                 * Опции кластеров указываем в кластеризаторе с префиксом "cluster".
                 * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/ClusterPlacemark.xml
                 */
                clusterDisableClickZoom: false,
                clusterHideIconOnBalloonOpen: false,
                geoObjectHideIconOnBalloonOpen: false,
                gridSize: 80
            });

            objectManager.add({
                type: 'FeatureCollection', 
                // @ts-ignore
                features: pvzs.map(item => ({
                    'type': 'Feature', 
                    'id': `${item.delivery_method_id}:${item.id}`, 
                    'geometry': {
                        'type': 'Point', 
                        'coordinates': [ item.latitude, item.longitude ]
                    },
                    'options': {
                        preset: item.delivery_method_id.startsWith('manual_') ? 
                            'islands#redDotIcon' : 
                            'islands#greenCircleDotIconWithCaption',
                        // Разукрашиваем собственные ПВЗ отдельным цветом
                        iconColor: item.delivery_method_id.startsWith('manual_') ? '#ff0000' : '#64ad1b',
                        openEmptyBalloon: true
                    },
                    'properties': {
                        balloonContentHeader: getContentHeader(item),
                        deliveryMethodId: item.delivery_method_id,
                        pvzId: item.id,
                        deliveryPrice: item.delivery_price,
                        deliveryDays: item.delivery_days,
                        loaded: false,
                        payments: item.payments
                    } }
                ))
            });

            // @ts-ignore
            objectManager.events.add('propertieschange', function(e) {
                console.log('properties is changed!', e);
            });

            // @ts-ignore
            objectManager.events.add('balloonopen', function (e) {
                const objectId = e.get('objectId');
                const object = objectManager.objects.getById(objectId);
                // @ts-ignore
                let objects, cluster, 
                    isCluster = false;

                if (object) {
                    objects = [ object ];
                } else {
                    cluster = objectManager.clusters.getById(objectId);
                    objects = cluster.features;
                    isCluster = true;
                }

                // @ts-ignore
                objects = objects.filter(item => !item.properties.loaded);
                if (objects.length) {
                    deliveriesRequest(
                        '/widget/pvzs/info/',
                        {
                            body: {
                                // @ts-ignore
                                pvzs: JSON.stringify(objects.map(item => ({
                                    delivery_method_code: item.properties.deliveryMethodId, 
                                    pvz_id: item.properties.pvzId
                                })))
                            },
                        }
                    ).then((response) => {
                        // @ts-ignore
                        for (const object of objects) {
                            const item = response.pvzs.find(
                                // @ts-ignore
                                i => object.properties.deliveryMethodId === i.delivery_method_id && 
                                    object.properties.pvzId === i.pvz_id
                            );
                            setObjectProperties(objectManager, object, item);
                        }

                        // Почемуто сам он не перерисовывает содержимое балуна кластера, 
                        // если его не заставить сделать это ручками
                        if (isCluster && objectManager.clusters.balloon.isOpen(objectId)) {
                            const clusterData = objectManager.clusters.balloon.getData();
                            objectManager.clusters.balloon.setData(clusterData);
                        }
                    });
                }
            });

            myMap.geoObjects.add(objectManager);

            // this sholud be called only when map is visible
            myMap.setBounds(objectManager.getBounds(), {
                checkZoomRange: true,
                duration: 500, 
                zoomMargin: 3
            });
        });
    }, [ city ]);

    useEffect(() => {
        // @ts-ignore
        ymaps.ready(init);
        function init(){
            console.log('init map!');

            // @ts-ignore
            myMap = new ymaps.Map('map', {
                center: [ 55.755814, 37.617635 ],
                zoom: 10,
            });
        }
    }, []);

    const className = classNames({
        [ styles.Wrapper ]: true,
        [ styles.IsMobile ]: IS_MOBILE,
    });

    return (
        <div className={ className }>
            <div id="map" style={{ width: '100%', height: '100%' }} />
        </div>
    );
};

export default Map;
