import PaymentMethods from '../paymentMethods/PaymentMethods';
import OrderCompletion from '../orderCompletion/OrderCompletion';

const Payment = (): JSX.Element => {
    return (
        <>
            <PaymentMethods />
            <OrderCompletion />
        </>
    );
};

export default Payment;
