
import classNames from 'classnames';

import { IS_MOBILE } from '../../constants';
import { useAppSelector } from '../../hooks';
import { getProducts } from '../../store/orderSlice';
import ProductQuantity from './ProductQuantity';
import { formatPrice } from '../../utils/formats';

import styles from './Cart.module.css';

const Cart = (): JSX.Element => {
    const products = useAppSelector(getProducts);
    const classNameWrapper = classNames(styles.Wrapper, { [styles.IsMobile]: IS_MOBILE });

    return (
        <div className={ classNameWrapper }>
            { products.map(item => (
                <div className={ styles.CartContainer } key={ item.id }>
                    <div className={ styles.CartProduct }>
                        <div className={ styles.ImageProduct }>
                            <img className={ styles.CartImg } src={ item.image_url } alt={ 'Image product' }/>
                        </div>
                        <div className={ styles.InfoProduct }>
                            <div className={ styles.InfoNameProduct }>
                                { item.name }
                            </div>
                            <div className={ styles.ProductParameters }>
                                <div className={ styles.InfoParametersProduct }>
                                    { item.features.map((feature, id) => (
                                        <div className={ styles.Feature } key={ id }>
                                            { feature.name + ':' }&nbsp;
                                            <div className={ styles.FeatureValue }>{ feature.value }</div>
                                        </div>
                                    )) }
                                </div>
                                <div className={ styles.InfoQuantityProduct }>
                                    <div className={ styles.InfoQuantityText }>
                                        Количество:
                                    </div>
                                    <ProductQuantity product={ item } />
                                </div>
                            </div>
                        </div>
                        <div className={ styles.PriceContainer }>
                            <div className={ styles.Price }>
                                <div>{ formatPrice(item.quantity * item.price) } ₽</div>
                                { item.old_price && item.price !== item.old_price ?
                                    <div className={ styles.OldPriceProduct }>
                                        { formatPrice(item.quantity * item.old_price) } ₽
                                    </div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )) }
        </div>);
};
export default Cart;
