import { useCallback, useEffect, useRef, CompositionEvent, ClipboardEvent, KeyboardEvent } from 'react';

import Input, { InputProps } from './input';

const getInputNumbersValue = function(input: HTMLInputElement) {
    // Return stripped input value — just numbers
    return input.value.replace(/\D/g, '');
};

const onPhonePaste = function (e: ClipboardEvent<HTMLInputElement>) {
    const input = e.currentTarget;
    const inputNumbersValue = getInputNumbersValue(input);

    const pasted = e.clipboardData;
    if (pasted) {
        const pastedText = pasted.getData('Text');
        if (/\D/g.test(pastedText)) {
            // Attempt to paste non-numeric symbol — remove all non-numeric symbols,
            // formatting will be in onPhoneInput handler
            input.value = inputNumbersValue;
            return;
        }
    }
};

const onPhoneInput = function(e: CompositionEvent<HTMLInputElement>) {
    const input = e.currentTarget;
    const inputNumbersValue = getInputNumbersValue(input);

    if (!inputNumbersValue) {
        return input.value = '';
    }

    if (input.value.length !== input.selectionStart) {
        const symbol = e.nativeEvent.data;
        // Editing in the middle of input, not last symbol
        if (symbol && /\D/g.test(symbol)) {
            // Attempt to input non-numeric symbol
            input.value = inputNumbersValue;
        }

        return;
    }

    input.value = getFormatted(inputNumbersValue);
};

const getFormatted = (raw: string): string => {
    let formatted = '';

    if ([ '7', '8', '9' ].indexOf(raw[0]) > -1) {
        if (raw[0] === '9') raw = '7' + raw;
        const firstSymbols = (raw[0] === '8') ? '8' : '+7';
        formatted = firstSymbols + ' ';
        if (raw.length > 1) {
            formatted += '(' + raw.substring(1, 4);
        }
        if (raw.length >= 5) {
            formatted += ') ' + raw.substring(4, 7);
        }
        if (raw.length >= 8) {
            formatted += '-' + raw.substring(7, 9);
        }
        if (raw.length >= 10) {
            formatted += '-' + raw.substring(9, 11);
        }
    } else {
        formatted = '+' + raw.substring(0, 16);
    }
    return formatted;
};

// TODO: подумать тут
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
interface PhoneInputProps extends InputProps {
    value: string;
    onChange: (value: string) => void;
}

const PhoneInput = ({ value, onChange, ...rest }: PhoneInputProps): JSX.Element => {
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (ref.current && value) {
            ref.current.value = getFormatted(value || '');
        }
    }, []);


    const _onChange = useCallback((e: any) => {
        const value = getInputNumbersValue(e.target);
        onChange(value);
    }, []);

    const onPhoneKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
        // Clear input after remove last symbol
        const inputValue = e.currentTarget.value.replace(/\D/g, '');
        if (e.code === 'Backspace' && inputValue.length <= 1) {
            e.currentTarget.value = '';
            onChange('');
        }
    }, []);

    return (
        <Input
            { ...rest }
            type="tel"
            // Это триггерит проблему с перебрасыванием курсора в конец строки,
            // поразбирваться в причинах не помешает
            // value={ getFormatted(value || '') }
            innerRef={ ref }
            onInput={ onPhoneInput }
            onChange={ _onChange }
            onPaste={ onPhonePaste }
            onKeyDown={ onPhoneKeyDown }
            maxLength={ 20 }
        />
    );
};

export default PhoneInput;
