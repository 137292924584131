import { useCallback, useState } from 'react';

import Layout from '../layout/Layout';
import Cart from '../cart/Cart';
import CartSlider from '../cartSlider/CartSlider';
import Personality from '../personality/Personality';
import Delivery from '../delivery/Delivery';
import PaymentMethods from '../paymentMethods/PaymentMethods';
import Payment from '../payment/Payment';
import Button from '../../ui/button';
import Icon from '../../ui/icon';
import OrderCompletion from '../orderCompletion/OrderCompletion';
import { IframeEventTypes, sendMessage } from '../../messages';
import { useDispatch } from 'react-redux';
import {
    validatePersonalityThunk,
    isPersonalityValidGetter,
    validateDeliveryThunk,
    isDeliveryValidGetter,
    needToShowPersonalityError,
    needToShowDeliveryError,
} from '../../store/formStateSlice';
import store from '../../store';
import { IS_MOBILE } from '../../constants';
import { useAppSelector } from '../../hooks';

import styles from './StepManager.module.css';


interface LayoutContentProps {
    onClick: () => void;
    step?: number;
}

const CloseButton = () => {
    const onCloseClick = useCallback(() => {
        sendMessage(IframeEventTypes.CLOSE);
    }, []);

    return (
        <Button type="icon" onClick={ onCloseClick }>
            <Icon src="/icons/close.svg"/>
        </Button>
    );
};

const ContinueButton = ({ onClick }: LayoutContentProps) => {
    return (
        <div>
            <Button type="normal" onClick={ onClick }>
                Продолжить
            </Button>
        </div>
    );
};

const BackButton = ({ onClick, step }: LayoutContentProps) => {
    if (step === 1){
        return null;
    }
    else {
        return (
            <Button type="icon" onClick={ onClick }>
                <Icon src="/icons/back.svg"/>
            </Button>
        );
    }
};

const MobileContent = ({ step, onClick }: LayoutContentProps) => {
    const dispatch = useDispatch();
    const hasPersonalityError = useAppSelector(needToShowPersonalityError);
    const hasDeliveryError = useAppSelector(needToShowDeliveryError);
    const deliveryErrorMessage = hasDeliveryError ? 'Пожалуйста, заполните все обязательные поля' : '';
    const personalityErrorMessage = hasPersonalityError ? 'Пожалуйста, заполните все обязательные поля' : '';

    const _onClickStepOne = async () => {
        await dispatch(validatePersonalityThunk());
        const isValid = isPersonalityValidGetter(store.getState());
        if (isValid) {
            onClick();
        }
    };
    const _onClickStepTwo = async () => {
        await dispatch(validateDeliveryThunk());
        const isValid = isDeliveryValidGetter(store.getState());
        if (isValid) {
            onClick();
        }
    };

    if (step === 1){
        return (
            <>
                <Personality />
                <div className={ styles.ContinueButton }>
                    <ContinueButton onClick={ _onClickStepOne }/>
                    <div className={ styles.Error }>
                        { personalityErrorMessage }
                    </div>
                </div>
            </>
        );
    }
    else if (step === 2){
        return (
            <>
                <Delivery />
                <div className={ styles.ContinueButton }>
                    <ContinueButton onClick={ _onClickStepTwo }/>
                    <div className={ styles.Error }>
                        { deliveryErrorMessage }
                    </div>
                </div>
            </>
        );
    }
    else {
        return <Payment />;
    }
};

const ContentMobile = ({ step, onClick }: LayoutContentProps) => {
    return (
        <div className={ styles.ContentWrapper }>
            <div className={ styles.MobileContent }>
                <MobileContent step={ step } onClick={ onClick }/>
            </div>
            <div className={ styles.MobileCartProduct }>
                <CartSlider />
            </div>
        </div>
    );
};

const ContentDesktop = () => {
    return (
        <>
            <Cart />
            <Personality />
            <Delivery />
            <PaymentMethods />
        </>
    );
};

const resetScroll = () => {
    if (!IS_MOBILE) {
        return;
    }

    document.scrollingElement && (document.scrollingElement.scrollTop = 0);
};

const StepManager = (): JSX.Element => {
    const [ step, setStep ] = useState(1);
    const goToPreviousStep = useCallback(() => {
        setStep(Math.max(1, step - 1));
        resetScroll();
    }, [ step ]);
    const goToNextStep = useCallback(async () => {
        setStep(Math.min(3, step + 1));
        resetScroll();
    }, [ step ]);

    const content = IS_MOBILE ? <ContentMobile step={ step } onClick={ goToNextStep }/> : <ContentDesktop />;

    return (
        <Layout
            mainContent={ content }
            closeButton={ <CloseButton /> }
            summary={ <OrderCompletion /> }
            step={ step }
            backButton={ <BackButton step={ step } onClick={ goToPreviousStep }/> }
        />
    );
};

export default StepManager;
