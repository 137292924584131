import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { IS_MOBILE } from '../../constants';
import { useAppSelector } from '../../hooks';
import { 
    getSelectedDeliveryOrDeliveryType, 
    getDelivery,
    getDeliveryAddress,
    getPvz,
} from '../../store/orderSlice';

import styles from './DeliveryInfo.module.css';
    
const DeliveryInfo = (): JSX.Element => {
    const selectedDeliveryOrDeliveryType = useAppSelector(getSelectedDeliveryOrDeliveryType);
    const delivery = useAppSelector(getDelivery);
    const deliveryAddress = useAppSelector(getDeliveryAddress);
    const pvz = useAppSelector(getPvz);

    const deliveryType = selectedDeliveryOrDeliveryType?.deliveryType || selectedDeliveryOrDeliveryType?.delivery?.type;
    const deliveryDays = delivery?.days;

    const addressText = deliveryType === 'pvz' ? pvz?.address : deliveryAddress?.raw;

    const classNameWrapper = classNames(styles.DeliveryContainer, { [styles.IsMobile]: IS_MOBILE });

    return (
        <div className={ classNameWrapper }>
            <div className={ styles.Delivery }>
                <div>Доставка:&nbsp;</div>
                <div>
                    <span className={ styles.Border }>
                        { deliveryType === 'post' ? 'в отделение почты' 
                            : deliveryType === 'pvz' ? 'в пункт выдачи' : 'курьером'
                        }
                    </span>
                </div>
            </div>
            { addressText &&     
                <div className={ styles.DeliveryAddress }>
                    { addressText }
                </div>
            }
            { deliveryDays && 
                <div className={ styles.Date }>
                    <div>Срок достаки:&nbsp;</div>
                    <div>
                        <span className={ styles.Border }>
                            { deliveryDays }
                            <FormattedMessage id="Delivery_days" values={{ num: deliveryDays }} />
                        </span>
                    </div>
                    { /* Возможно, стоит будет постараться поработать над тем, 
                    чтобы расчитывать дату хотя бы иногда
                    <div>Дата:&nbsp;</div>
                    <span className={ styles.Border }>16 марта</span> */ }
                </div>
            }
        </div>
    );
};

export default DeliveryInfo;
