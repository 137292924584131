import { Ref, useMemo, InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';

import { IS_CHROME, IS_MOBILE } from '../../constants';

import styles from './input.module.css';


export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    innerRef?: Ref<HTMLInputElement>;
    label: string;
    containerClassName?: string;
    hasError?: boolean;
}

function Input({ value, onChange, label, containerClassName, innerRef, hasError, ...rest }: InputProps): JSX.Element {
    const inputId = useMemo(() => uniqueId('input-'), []);
    const classNameWrapper = classNames(styles.Container, containerClassName, { [styles.IsMobile]: IS_MOBILE });

    return (
        <div className={ classNameWrapper }>
            <input
                { ...rest }
                ref={ innerRef }
                className={ classNames(styles.Input, { [ styles.ErrorInput ]: hasError }) }
                value={ value }
                onChange={ onChange }
                name={ IS_CHROME ? Math.random().toString(36).substring(7) : undefined }
                id={ inputId }
                required
                autoComplete={ IS_CHROME ? 'chrome-off' : 'off' }
            />
            <label className={ classNames(styles.Label, { [ styles.ErrorLabel ]: hasError }) } htmlFor={ inputId }>
                { label }
            </label>
        </div>
    );
}

export default Input;
