import classNames from 'classnames';

import Button from '../../ui/button';
import Icon from '../../ui/icon';
import { IS_MOBILE } from '../../constants';

import styles from './PreviouslySelectedItems.module.css';

interface Item {
    label: string;
    value: string | number;
    isSelected: boolean;
}

interface PreviouslySelectedItemsProps {
    label: string;
    items: Array<Item>;
    onSelect: (item: Item) => void;
}

const PreviouslySelectedItems = ({ label, items, onSelect }: PreviouslySelectedItemsProps): JSX.Element => {
    const classNameWrapper = classNames(styles.Wrapper, { [styles.IsMobile]: IS_MOBILE });
    return (
        <div className={ classNameWrapper }>
            <div className={ styles.Title }>
                { label }
            </div>
            { items.map((item) => (
                <div
                    key={ item.value }
                    className={ classNames(styles.Item, { [styles.ItemSelected]: item.isSelected }) }
                >
                    <div className={ styles.Label }>
                        { item.label }
                    </div>
                    <div className={ styles.RightContent }>
                        { item.isSelected ? 
                            (<div className={ styles.Selected }>
                                <div className={ styles.CheckmarkContainer }>
                                    <Icon src="/icons/checkmark.svg" className={ styles.Checkmark }/>
                                </div>
                                Выбран
                            </div>) :
                            <Button type="normal-outline" size="xs" onClick={ () => onSelect(item) }>
                                Выбрать
                            </Button>
                        }
                    </div>
                </div>)) }
        </div>
    );
};

export default PreviouslySelectedItems;
