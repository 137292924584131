import { useCallback } from 'react';
import classNames from 'classnames';

import Summary from '../summary/Summary';
import Button from '../../ui/button';
import { GLOBAL_CLASS_NAMES, IS_MOBILE } from '../../constants';
import { scrollIntoViewIfNeeded } from '../../utils/scroll-to';
import { useAppSelector, useAppDispatch } from '../../hooks';
import {
    sendOrder as sendOrderAction,
    getFormState,
    validatePersonalityThunk,
    isFormValidGetter,
    validateDeliveryThunk,
    needToShowOrderCompletionError,
} from '../../store/formStateSlice';
import DeliveryInfo from './DeliveryInfo';
import store from '../../store';

import styles from './OrderCompletion.module.css';


const OrderCompletion = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const sendOrder = useCallback(() => dispatch(sendOrderAction()), []);
    const formState = useAppSelector(getFormState);
    const hasError = useAppSelector(needToShowOrderCompletionError);
    const errorMessage = hasError ? 'Пожалуйста, заполните все обязательные поля' : '';

    const classNameWrapper = classNames(styles.Wrapper, { [styles.IsMobile]: IS_MOBILE });

    const onClick = useCallback(async () => {
        await dispatch(validatePersonalityThunk());
        await dispatch(validateDeliveryThunk());
        const isValid = isFormValidGetter(store.getState());
        if (isValid) {
            sendOrder();
        } else {
            scrollIntoViewIfNeeded(`.${GLOBAL_CLASS_NAMES.formItemWithError}`);
        }
    }, [ ]);

    return (
        <div className={ classNameWrapper }>
            <div className={ styles.DeliveryAndSummaryContainer }>
                <Summary />
                <DeliveryInfo />
            </div>
            <div className={ styles.ButtonContainer }>
                <div>
                    <Button
                        type="accent"
                        onClick={ onClick }
                        isLoading={ formState === 'sending' }
                    >
                        Оформить заказ
                    </Button>
                    <div className={ styles.Error }>
                        { errorMessage }
                    </div>
                </div>
                <div className={ styles.PrivacyPolicy }>
                    Нажимая на кнопку, я даю свое согласие на&nbsp;
                    <span className={ styles.Border }>
                        { /* TODO: нужен какой-нибудь док */ }
                        обработку персональных данных.
                    </span>
                </div>
            </div>
        </div>
    );
};

export default OrderCompletion;
