import React, { useState, useMemo, useCallback, ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import * as types from '../../store/types';
import {
    getSelectedDeliveryOrDeliveryType,
    selectDeliveryOrDeliveryType,
    getDeliveryAddress,
    getDeliveryType,
    getPvz,
    getCity,
    getPreviouslySelectedPvzList,
    getPreviouslySelectedAddressList,
    changeAddress as changeAddressAction,
    setPreviouslySelectedDeliveryAddress,
    setPreviouslySelectedPvz,
    manualChangeCity,
} from '../../store/orderSlice';
import Radio from '../../ui/radio';
import Input from '../../ui/input/index';
import Modal from '../../ui/modal/Modal';
import Skeleton from '../../ui/skeleton/Skeleton';
import Button from '../../ui/button';
import { IS_MOBILE } from '../../constants';
import Map from '../map/Map';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { 
    getDeliveries, 
    getDeliveriesLoadingState,
} from '../../store/deliveriesSlice';
import AutocompleteInput from '../../ui/input/autocomplete-input';
import { request } from '../../api';
import { Item } from '../../ui/input/autocomplete-input';
import PreviouslySelectedItems from './PreviouslySelectedItems';
import {
    getValidation,
    validateAddressThunk,
    clearError,
} from '../../store/formStateSlice';
import FormItem from '../../ui/form/FormItem';

import styles from './Delivery.module.css';


interface PromptProps {
    prompts: Array<string>,
    comment: string,
    setComment: (newComment:string) => void,
}

interface RadioItem {
    id: string;
    label: string;
    rightContent: React.ReactNode;
}

interface City {
    full_name: string;
    aoguid: string;
}

interface Address {
    settlement_with_type: string;
    street_with_type: string;
    house: number;
    house_type: string;
    block: number;
    block_type: string;
    flat: number;
    flat_type: string;
    aoguid: string | null;
}

const getRadioItems = (deliveries: Array<types.Delivery>) => {
    let withPvz = false;

    const pvzDeliveries = deliveries.filter(item => item.type === 'pvz');
    const pvzDeliveriesPrices = pvzDeliveries.map(item => item.price);
    const pvzDeliveriesDays = pvzDeliveries.map(item => item.days);
    const [ 
        minPvzPrice, 
        maxPvzPrice,
        minPvzDays,
        maxPvzDays,
    ] = [
        Math.min(...pvzDeliveriesPrices),
        Math.max(...pvzDeliveriesPrices),
        Math.min(...pvzDeliveriesDays),
        Math.max(...pvzDeliveriesDays),
    ];

    const items: RadioItem[] = [];
    for (const delivery of deliveries) {
        if (delivery.type === 'pvz') {
            if (!withPvz) {
                withPvz = true;
                items.push({
                    id: '__pvz',
                    label: 'До пункта выдачи',
                    rightContent: <>
                        { 
                            (minPvzPrice === maxPvzPrice ? minPvzPrice : minPvzPrice + '-' + maxPvzPrice) + ' ₽, ' +
                            (minPvzDays === maxPvzDays ? minPvzDays : minPvzDays + '-' + maxPvzDays) 
                        }
                        <FormattedMessage id="Delivery_days" values={{ num: maxPvzDays }}/>
                    </>
                });
            }
        } else {
            items.push({
                id: delivery.id,
                label: delivery.name,
                rightContent: <>
                    { [
                        <span key="price">{ delivery.price } ₽</span>,
                        delivery.days !== null && <span key="days">
                            , { delivery.days } <FormattedMessage id="Delivery_days" values={{ num: delivery.days }}/>
                        </span>
                    ].filter(Boolean) }
                </>
            });
        }
    }

    return items;
};

const DeliveriesList = () => {
    const selectedDeliveryOrDeliveryType = useAppSelector(getSelectedDeliveryOrDeliveryType);
    const deliveries = useAppSelector(getDeliveries);
    const radioItems = useMemo(() => getRadioItems(deliveries), [ deliveries ]);
    const deliveriesIsLoading = useAppSelector(getDeliveriesLoadingState) !== 'done';

    const dispatch = useAppDispatch();

    const value = (
        selectedDeliveryOrDeliveryType?.deliveryType === 'pvz' ||
        selectedDeliveryOrDeliveryType?.delivery?.type === 'pvz'
    ) ? 
        '__pvz' : 
        selectedDeliveryOrDeliveryType?.delivery?.id;

    const onChange = useCallback((event) => {
        if (event.target.value !== '__pvz') {
            const delivery = deliveries.find(
                item => item.id === event.target.value
            ) as types.CourierDelivery | types.PostDelivery;
            dispatch(selectDeliveryOrDeliveryType({ delivery, deliveryType: undefined }));
        } else {
            dispatch(selectDeliveryOrDeliveryType({ deliveryType: 'pvz', delivery: undefined }));
        }
    }, [ deliveries ]);

    if (deliveriesIsLoading) {
        return <Skeleton rows={ radioItems.length || 4 } />;
    }

    if (!deliveries.length) {
        return <div>В ваш город доставка не осуществляется...</div>;
    }

    return (
        <Radio.Group 
            value={ value }
            onChange={ onChange }
        >
            { radioItems.map((item) => (
                <Radio value={ item.id } key={ item.id } >
                    <div className={ styles.DeliveryServiceWrapper }>
                        <div className={ styles.DeliveryServiceName }>
                            { item.label }
                        </div>
                        <div className={ styles.DeliveryServiceCostAndTime }>
                            { item.rightContent }
                        </div>
                    </div>
                </Radio>
            )) }
        </Radio.Group>
    );
};

const PromptList = ({ prompts, comment, setComment }: PromptProps) => {

    const onClickPrompts = (prompt: string) => {
        const newComment = (comment + ' ' + prompt).trim();
        setComment(newComment);
    };

    return (
        <div className={ styles.PromptContainer } >
            { prompts.map((item, index) => (
                <div className={ styles.HintText } onClick={ () => onClickPrompts(item) } key={ index }>
                    { item }
                </div>
            )) }
        </div>
    );
};

function getCities(value: string) {
    return request(
        '/get_city_autocomplete',
        {
            query: {
                query: value,
                limit: '10'
            }
        }
    ).then((result) => result.map((item: City) => {
        return {
            id: item.aoguid,
            label: item.full_name,
        };
    }));
}

function getAddress(city: string, value: string) {
    return request(
        '/get_address_autocomplete',
        {
            query: {
                city: city,
                query: value.trim()
            }
        }
    ).then(result => {
        return result.map((item:Address) => {
            const house = [ item.house_type, item.house ].filter(Boolean).join(' ');
            const block = [ item.block_type, item.block ].filter(Boolean).join(' ');
            const flat = [ item.flat_type, item.flat ].filter(Boolean).join(' ');
            const name = [
                item.settlement_with_type,
                item.street_with_type,
                house,
                block,
                flat
            ].filter(Boolean).join(', ');
            return {
                id: name,
                label: name,
                aoguid: item.aoguid,
            };
        });
    });
}

function _splitCity(city: string) {
    return city.split(',')[0];
}

function _getPreviouslySelectedPvzLabel(pvz: types.Pvz, cityId: string) {
    return [
        pvz.delivery_method_name,
        pvz.city_fias_id !== cityId && _splitCity(pvz.city_name),
        pvz.address
    ].filter(Boolean).join(', ');
}

function _getPreviouslySelectedDeliveryAddressLabel(address: types.Address, cityId: string) {
    return [
        address.city_fias_id !== cityId && _splitCity(address.city_name),
        address.raw
    ].filter(Boolean).join(', ');
}

const Delivery = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const selectedDeliveryOrDeliveryType = useAppSelector(getSelectedDeliveryOrDeliveryType);
    const deliveryType = useAppSelector(getDeliveryType);
    const selectedPvz = useAppSelector(getPvz);
    const address = useAppSelector(getDeliveryAddress);
    const city = useAppSelector(getCity);
    const previouslySelectedPvzList = useAppSelector(getPreviouslySelectedPvzList);
    const previouslySelectedAddressList = useAppSelector(getPreviouslySelectedAddressList);
    const validation = useAppSelector(getValidation);
    const { address: { errorMessage: addressError } } = validation.delivery;

    const [ cityName, setCityName ] = useState(city.name);

    const onChangeCity = useCallback((item: Item)=>{
        const [ cityName, cityFiasId ] = [ item.label, item.id as string ];
        setCityName(cityName);
        dispatch(manualChangeCity({ cityName, cityFiasId }));
    }, [ dispatch ]);

    const onInputCity = useCallback((value: string) => {
        setCityName(value);
    }, [ dispatch ]);

    const onBlurCity = useCallback(() => {
        setCityName(city.name);
    }, [ city ]);

    const changeAddress = useCallback((item: { label: string, aoguid: string | null }) => {
        dispatch(changeAddressAction({ raw: item.label, aoguid: item.aoguid }));
    }, [ dispatch ]);

    const inputAddress = useCallback((value:string) => {
        dispatch(changeAddressAction({ raw: value, aoguid: null }));
        if (value) {
            dispatch(clearError([ 'delivery', 'address' ]));
        }
    }, [ dispatch ]);

    const onBlurAddress = useCallback(() => {
        dispatch(validateAddressThunk());
    }, []);

    const [ comment, setComment ] = useState('');

    const [ prompts ] = useState([ 
        'Позвонить за час',
        'Уточнить адрес доставки',
        'Доставить до'
    ]);

    const onChangeComment = (e: ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value);
    };

    const containerClassName = classNames(styles.ContentContainer, { [styles.IsMobile]: IS_MOBILE });

    const [ mapVisible, setMapVisible ] = useState(false);
    const showMap = useCallback(() => setMapVisible(true), []);
    const hideMap = useCallback(() => setMapVisible(false), []);
    const getAddressCallback = useCallback((value:string) => getAddress(cityName, value), [ cityName ]);

    return (
        <div>
            <div className={ containerClassName }>
                <div className={ styles.TitleContainer }>
                    Варианты доставки
                </div>
                <AutocompleteInput
                    value={ cityName }
                    getItems={ getCities }
                    label="Город / село / деревня"
                    onChange={ onChangeCity }
                    onInput={ onInputCity }
                    onBlur={ onBlurCity }
                />
                <div className={ styles.DeliveriesContainer }>
                    <DeliveriesList />
                </div>

                <Modal
                    visible={ mapVisible }
                    onClose={ hideMap }
                >
                    { mapVisible && <Map onClose={ hideMap }/> }
                </Modal>

                { 
                    (
                        selectedDeliveryOrDeliveryType?.deliveryType === 'pvz' ||
                        selectedDeliveryOrDeliveryType?.delivery?.type === 'pvz'
                    ) &&
                    <>
                        <FormItem
                            className={ styles.ChoosePvzWrapper }
                            errorMessage={ validation.delivery.pvz.errorMessage }
                        >
                            <div className={ styles.ChoosePvz }>
                                <div className={ styles.ChoosePvzButton }>
                                    { selectedPvz ?
                                        <Button type="normal-outline" onClick={ showMap }>
                                            Изменить пункт выдачи
                                        </Button> :
                                        <Button 
                                            type="normal" 
                                            onClick={ showMap }
                                            hasError={ Boolean(validation.delivery.pvz.errorMessage) }
                                        >
                                            Выбрать пункт выдачи
                                        </Button>
                                    }
                                </div>
                                { !IS_MOBILE &&
                                <div className={ styles.ChoosePvzText }>
                                    Акутуальные пункты выдачи отображены на карте
                                </div>
                                }
                            </div>
                        </FormItem>
                        
                        { previouslySelectedPvzList.length > 0 &&
                            <PreviouslySelectedItems
                                label="Пункты выдачи:"
                                items={ previouslySelectedPvzList.map(item => ({
                                    label: _getPreviouslySelectedPvzLabel(item, city.fias_id as string),
                                    value: item.id,
                                    isSelected: item.id === selectedPvz?.id
                                })) }
                                onSelect={ (selectedItem) => {
                                    const pvzId = selectedItem.value as string;
                                    const pvz = previouslySelectedPvzList.find(pvz => pvz.id === pvzId);
                                    if (!pvz) {
                                        throw new Error('Cant find pvz!');
                                    }
                                    dispatch(setPreviouslySelectedPvz(pvz));
                                    setCityName(pvz.city_name);

                                } }
                            /> 
                        }
                    </>
                }

                { (deliveryType === 'courier' || deliveryType === 'post') &&
                <div className={ styles.ShippingInfoContainer }>
                    <FormItem
                        className={ styles.DeliveryAddressContainer }
                        errorMessage={ validation.delivery.address.errorMessage }
                    >
                        <AutocompleteInput<{ label: string, id: string, aoguid: string | null }>
                            getItems={ getAddressCallback }
                            label={
                                deliveryType === 'courier' ? 'Адрес доставки*' : 'Ваш адрес*'
                            }
                            value={ address?.raw || '' }
                            onChange={ changeAddress }
                            onInput={ inputAddress }
                            onBlur={ onBlurAddress }
                            hasError={ Boolean(addressError) }
                        />
                    </FormItem>
                    { previouslySelectedAddressList.length > 0 &&
                    <PreviouslySelectedItems 
                        label="Мои адреса:"
                        items={ previouslySelectedAddressList.map(item => ({
                            label: _getPreviouslySelectedDeliveryAddressLabel(item, city.fias_id as string),
                            value: item.raw,
                            isSelected: item.raw === address?.raw,
                        })) }
                        onSelect={ (selectedItem) => {
                            const addressRaw = selectedItem.value as string;
                            const address = previouslySelectedAddressList.find(address => address.raw === addressRaw);
                            if (!address) {
                                throw new Error('Cant find pvz!');
                            }
                            dispatch(setPreviouslySelectedDeliveryAddress(address));
                            setCityName(address.city_name);
                        } }
                    /> 
                    }
                    { deliveryType !== 'post' &&
                    <div>
                        <PromptList prompts={ prompts } comment={ comment } setComment={ setComment }/>
                        <Input
                            value={ comment }
                            onChange={ onChangeComment }
                            label="Комментарий для курьера"
                        />
                    </div>
                    }
                </div>
                }
            </div>
        </div>
    );
};

export default Delivery;
