import { useState, useEffect } from 'react';
import classNames from 'classnames';

import Autocomplete from 'react-autocomplete';

import Input from './input';

import style from './autocomplete-input.module.css';

interface AutocompleteProps<ItemType> {
    getItems: (value:string) => Promise<ItemType[]>,
    label: string,
    value: string,
    onChange: (value: any) => void;
    onInput: (value: string) => void;
    onBlur?: () => void;
    hasError?: boolean;
}

export interface Item {
    id: number | string,
    label: string,
}

const AutocompleteInput = <ItemType extends Item = Item>({
    getItems,
    label, 
    value, 

    onChange, 
    onInput,
    onBlur,
    hasError,
}: AutocompleteProps<ItemType>): JSX.Element => {

    const [ menuItems, setMenuItems ] = useState<ItemType[]>([]);

    useEffect(()=>{getItems(value).then(res => {setMenuItems(res);});}, []);

    return (
        <Autocomplete
            items={ menuItems }
            value={ value }
            getItemValue={ item => item.label }
            renderItem={ (item, isHighlighted) => (
                <div key={ item.id } className={ classNames(style.Item, { [style.Selected]: isHighlighted }) }>
                    { item.label }
                </div>
            ) }
            renderInput={ ({ ref, ...props })=> (
                <Input
                    { ...props }
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    innerRef={ ref }
                    label={ label }
                    onChange={ (e) => {
                        onInput(e.currentTarget.value);
                        getItems(e.currentTarget.value).then(res => setMenuItems(res));
                    } }
                    hasError={ hasError }
                />
            ) }
            inputProps={{
                onBlur,
            }}
            wrapperStyle={{
                position: 'relative',
                display: 'block',
                flexDirection: 'column',
            }}
            renderMenu={ (items) => (
                <div className={ classNames({ [style.SuggestionWrapper]: items.length !== 0 }) }>
                    { items }
                </div>

            ) }
            onSelect={ (_, item)=>{
                onChange(item);
            } }
        />
    );
};

export default AutocompleteInput;
