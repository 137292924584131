import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';

import store from './store';
import { fetchOrder, getOrderLoadingState } from './store/orderSlice';
import { fetchDeliveries } from './store/deliveriesSlice';
import { fetchPaymentMethods } from './store/paymentMethodsSlice';
import { messages } from './locales/ru';
import StepManager from './components/stepManager/StepManager';
import { useAppSelector } from './hooks';
import { IncomingIframeEventTypes } from './messages';

import styles from './App.module.css';

store.dispatch(fetchOrder()).then(() => {
    store.dispatch(fetchDeliveries());
});
store.dispatch(fetchPaymentMethods());

const onRefreshMessage = (event: MessageEvent) => {
    if (event.data.type === IncomingIframeEventTypes.REFRESH) {
        store.dispatch(fetchOrder())
            .then(() => store.dispatch(fetchDeliveries()));
    }
};

const App = (): JSX.Element => {
    const loaded = useAppSelector(getOrderLoadingState) === 'done';

    useEffect(() => {
        window.addEventListener('message', onRefreshMessage);

        return () => window.removeEventListener('message', onRefreshMessage);
    }, []);

    if (!loaded) {
        return <div className={ styles.LoadingPlaceholder }>
            <div>Загружаемся...</div>
        </div>;
    }

    return (
        <IntlProvider locale="ru" messages={ messages }>
            <div className={ styles.Container }>
                <StepManager />
            </div>
        </IntlProvider>
    );
};

export default App;
