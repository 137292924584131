import { CLIENT_TOKEN } from '../constants';
import { ApiMethods } from '../types';

const API_HOST = process.env.REACT_APP_API_HOST;

type BodyRecord = Record<string, string | number | null>;
type Body = Record<string, string | number | null | BodyRecord>;
type Query = Record<string, string>;

interface ValidationErrorItem {
    loc: Array<string>;
    msg: string;
    type: string;
}

interface InvalidRequestData {
    detail: Array<ValidationErrorItem>;
}

export class InvalidRequestError extends Error {
    constructor(public data: InvalidRequestData) {
        super('InvalidRequestError');
    }
}

export class HttpError extends Error {}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const request = async (
    method: ApiMethods, 
    { body, query }: { body?: Body, query?: Query } = {}
) => {
    const httpMethod = body ? 'POST' : 'GET';

    let url = API_HOST + method;
    if (query) {
        url += '?' + new URLSearchParams(query).toString();
    }

    const response = await fetch(
        url,
        { 
            method: httpMethod,
            credentials: 'include',
            body: body ? JSON.stringify(body) : undefined,
            headers: {
                'X-Client-Token': CLIENT_TOKEN
            }
        }
    );

    if (!response.ok) {
        // Unproccesable entity
        if (response.status === 422) {
            const errorData = await response.json();
            throw new InvalidRequestError(errorData);
        } else {
            throw new HttpError(response.statusText);
        }
    }

    return response.json();
};
