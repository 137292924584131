import { 
    createSlice, 
    createAsyncThunk,
    PayloadAction,
} from '@reduxjs/toolkit';

import { request } from '../api';
import { IframeEventTypes, sendMessage } from '../messages';
import {
    DeliveryFields,
    FormState,
    PersonalityFields
} from './types';
import { getOrder } from './orderSlice';
import { CHECKOUT_ID } from '../constants';
import type { RootState } from './index';

const initialState: FormState = {
    formState: 'initilized',

    validation: {
        personality: {
            phone: {
                status: 'empty',
            },
            smsCode: {
                status: 'empty',
            },
            fullName: {
                status: 'empty',
            },
        },
        delivery: {
            address: {
                status: 'empty',
            },
            pvz: {
                status: 'empty',
            },
        }
    },
};

export const sendOrder = createAsyncThunk(
    'formState/sendOrder',
    async (_, { getState }) => {
        const state = getState() as RootState;

        const order = getOrder(state);
        const { cms_redirect_uri: location } = await request(
            '/send_order_to_cms',
            { 
                query: {
                    id: CHECKOUT_ID,
                },
                body: order,
            }
        );

        sendMessage(IframeEventTypes.REDIRECT, { location });
    },
);

const validatePhone = (value: string): [ boolean, string | undefined ] => {
    const withoutFormatting = value !== null ? value.replace(/\D/g, '') : '';
    if (!withoutFormatting.length) {
        return [ false, 'Обязательно к заполнению' ];
    }

    if (withoutFormatting.length < 11) {
        return [ false, 'Некоррекнтый номер' ];
    }

    return [ true, undefined ];
};

export const validatePhoneThunk = createAsyncThunk<boolean, void, { state: RootState }>(
    'formState/validatePhone',
    async (_, { dispatch, getState }) => {
        const state = getState();

        const { phone } = state.order.personality;

        const [ isValid, errorMessage ] = validatePhone(phone);
        if (!isValid) {
            const error: { key: ValidationKeys, value: string } = {
                key: [ 'personality', 'phone' ],
                value: errorMessage || ''
            };
            dispatch(setError(error));
        } else {
            dispatch(setValid([ 'personality', 'phone' ]));
        }
        return isValid;
    },
);

export const validateFullNameThunk = createAsyncThunk<void, void, { state: RootState }>(
    'formState/validateFullName',
    async (_, { dispatch, getState }) => {
        const state = getState();

        const { fullName } = state.order.personality;
        const isValid = fullName.trim() !== '';

        if (!isValid){
            const error: { key: ValidationKeys, value: string } = {
                key: [ 'personality', 'fullName' ],
                value: 'Обязательно к заполнению'
            };
            dispatch(setError(error));
        } else {
            dispatch(setValid([ 'personality', 'fullName' ]));
        }
    },
);

export const validatePersonalityThunk = createAsyncThunk<void, void, { state: RootState }>(
    'formState/validatePersonality',
    async (_, { dispatch }) => {
        await dispatch(validatePhoneThunk());
        await dispatch(validateFullNameThunk());
    }
);

export const validateAddressThunk = createAsyncThunk<void, void, { state: RootState }>(
    'formState/validateAddress',
    async (_, { dispatch, getState }) => {
        const state = getState();

        const isValid = state.order.address ? state.order.address.raw.trim() : false;
        if (!isValid) {
            const error: { key: ValidationKeys, value: string } = {
                key: [ 'delivery', 'address' ],
                value: 'Обязательно к заполнению'
            };
            dispatch(setError(error));
        } else {
            dispatch(setValid([ 'delivery', 'address' ]));
        }
    }
);

export const validatePVZThunk = createAsyncThunk<void, void, { state: RootState }>(
    'formState/validatePVZ',
    async (_, { dispatch, getState }) => {
        const state = getState();
        const isValid = Boolean(state.order.pvz);
        if (!isValid) {
            const error: { key: ValidationKeys, value: string } = {
                key: [ 'delivery', 'pvz' ],
                value: 'Выбирите ПВЗ'
            };
            dispatch(setError(error));
        } else {
            dispatch(setValid([ 'delivery', 'pvz' ]));
        }
    }
);

export const validateDeliveryThunk = createAsyncThunk<void, void, { state: RootState }>(
    'formState/validateDelivery',
    async (_, { dispatch, getState }) => {
        const state = getState();
        const deliveryType = state.order.delivery?.type;
        if (deliveryType === 'courier' || deliveryType === 'post'){
            await dispatch(validateAddressThunk());
        } else {
            await dispatch(validatePVZThunk());
        }
    }
);

export type ValidationKeys =
    ['personality', PersonalityFields] |
    ['delivery', DeliveryFields ];

export const formStateSlice = createSlice({
    name: 'formState',
    initialState,
    reducers: {
        setError: (
            state: FormState,
            { payload }: PayloadAction<{ key: ValidationKeys, value: string }>
        ) => {
            const [ component, field ] = payload.key;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.validation[component][field] = {
                status: 'error',
                errorMessage: payload.value
            };
        },
        setValid: (state: FormState, { payload }: PayloadAction<ValidationKeys>) => {
            const [ component, field ] = payload;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.validation[component][field] = {
                status: 'valid',
            };
        },
        clearError: (state: FormState, { payload }: PayloadAction<ValidationKeys>) => {
            const [ component, field ] = payload;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.validation[component][field] = {
                status: 'touched',
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendOrder.pending, (state) => {
                state.formState = 'sending';
            });
    },
});

export const reducer = formStateSlice.reducer;

export const { 
    setError,
    setValid,
    clearError,
} = formStateSlice.actions;

export const getFormState = (store: RootState): RootState['formState']['formState'] => store.formState.formState;

export const getValidation = (store: RootState): RootState['formState']['validation'] => store.formState.validation;

export const isPersonalityValidGetter = (store: RootState) => {
    return (
        store.formState.validation.personality.phone.status === 'valid' &&
        store.formState.validation.personality.fullName.status === 'valid'
    );
};

export const isDeliveryValidGetter = (store: RootState) => {
    return getDeliveryFieldsValidation(store).status === 'valid';
};

export const isFormValidGetter = (store: RootState): boolean => {
    return getFieldsValidation(store).every(item => item.status === 'valid');
};

const getDeliveryFieldsValidation = (store: RootState) => {
    const deliveryType = store.order.delivery?.type;
    return (deliveryType === 'courier' || deliveryType === 'post') ?
        store.formState.validation.delivery.address :
        store.formState.validation.delivery.pvz;
};

const getPersonalityFieldsValidation = (store: RootState) => {
    const validation = store.formState.validation;
    return [
        validation.personality.phone,
        validation.personality.fullName,
    ];
};

export const getFieldsValidation = (store: RootState) => {
    return getPersonalityFieldsValidation(store).concat(getDeliveryFieldsValidation(store));
};

export const needToShowOrderCompletionError = (store: RootState): boolean => {
    return getFieldsValidation(store).some(item => item.status === 'error');
};

export const needToShowPersonalityError = (store: RootState): boolean => {
    return getPersonalityFieldsValidation(store).some(item => item.status === 'error');
};

export const needToShowDeliveryError = (store: RootState): boolean => {
    return getDeliveryFieldsValidation(store).status === 'error';
};
