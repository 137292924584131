import styles from './Layout.mobile.module.css';

interface LayoutMobileProps {
    closeButton: JSX.Element;
    mainContent: JSX.Element;
    step: number;
    backButton?: JSX.Element

}

const LayoutMobile = ({ mainContent, closeButton, step, backButton }: LayoutMobileProps): JSX.Element => {
    return (
        <div className={ styles.Wrapper }>
            <div className={ styles.HeaderWrapper }>
                <div className={ styles.BackContainer }>
                    { backButton }
                </div>
                <div className={ styles.Header }>
                    Оформление заказа
                </div>
                <div className={ styles.CloseButtonContainer }>
                    { closeButton }
                </div>
            </div>
            <div className={ styles.PageNumber }>
                { step + ' из 3' }
            </div>
            <div className={ styles.MainContentWrapper }>
                { mainContent }
            </div>
        </div>
    );
};

export default LayoutMobile;
