import { useCallback, ChangeEvent } from 'react';
import classNames from 'classnames';

import { 
    getPaymentMethods,
    getPaymentMethodsLoadingState,
} from '../../store/paymentMethodsSlice';
import { getSelectedPaymentMethod, changePaymentMethod } from '../../store/orderSlice';
import Radio from '../../ui/radio';
import Skeleton from '../../ui/skeleton/Skeleton';
import { IS_MOBILE } from '../../constants';
import { useAppSelector, useAppDispatch } from '../../hooks';

import styles from './PaymentMethods.module.css';

const PaymentMethodsList = (): JSX.Element => {
    const paymentMethods = useAppSelector(getPaymentMethods);
    const selected = useAppSelector(getSelectedPaymentMethod);

    const dispatch = useAppDispatch();
    const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const paymentMethod = paymentMethods.find(item => item.id === event.target.value);
        if (!paymentMethod) {
            throw new Error('Can`t find payment method...');
        }
        dispatch(changePaymentMethod(paymentMethod));
    }, [ paymentMethods ]);

    const paymentMethodsLoaded = useAppSelector(getPaymentMethodsLoadingState) === 'done';

    if (!paymentMethodsLoaded) {
        return <Skeleton rows={ paymentMethods.length || 3 } />;
    }

    if (!paymentMethods.length) {
        return <div>Упс, что-то нет доступных способов оплаты...</div>;
    }

    return (
        <div className={ styles.PayMethod }>
            <Radio.Group
                value={ selected?.id }
                onChange={ onChange }
            >
                { paymentMethods.map((item) => (
                    <Radio value={ item.id } key={ item.id }>
                        <div className={ styles.PayMethodName }>
                            { item.name }
                        </div>
                    </Radio>
                )) }
            </Radio.Group>
        </div>
    );
};

const PaymentMethods = (): JSX.Element => {
    const classNameContainer = classNames(styles.Container, { [styles.IsMobile]: IS_MOBILE });

    return (
        <div className={ classNameContainer }>
            <div className={ styles.Title }>
                { IS_MOBILE ? 'Оплата' : 'Способы оплаты' }
            </div>
            <PaymentMethodsList />
        </div>);
};

export default PaymentMethods;
