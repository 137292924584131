import withPlatform from '../../hocs/withPlatform';
import LayoutDesktop from './Layout.desktop';
import LayoutMobile from './Layout.mobile';

const Layout = withPlatform(
    {
        mobile: LayoutMobile,
        desktop: LayoutDesktop
    }
);
export default Layout;
