import { useEffect, RefObject } from 'react';

export function useOutsideClick(ref: RefObject<HTMLElement>, handleClick: (event: Event) => void): void {
    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                handleClick(event);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ ref, handleClick ]);
}
