// TODO: Use whitelist here
const TARGET_ORIGIN = '*';

export enum IframeEventTypes {
    CLOSE,
    REDIRECT,
    UPDATE_QUANTITY,
}

export enum IncomingIframeEventTypes {
    REFRESH,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendMessage = (type: IframeEventTypes, payload: Record<string, any> = {}): void => {
    window.parent?.postMessage({
        type,
        payload,
    }, TARGET_ORIGIN);
};
