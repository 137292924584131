const observer = new IntersectionObserver(
    ([ entry ]) => {
        const elementIsVisible = entry.isIntersecting;
        if (!elementIsVisible) {
            entry.target.scrollIntoView({ behavior: 'smooth' });
        }

        observer.unobserve(entry.target);
    }, 
    { root: null, threshold: 0 }
);

export const scrollIntoViewIfNeeded = (selector: string) => {
    const element = document.querySelector(selector);
    if (!element) {
        return;
    }

    observer.observe(element);
};
