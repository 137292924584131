import styles from './Layout.desktop.module.css';

interface LayoutDesktopProps {
    mainContent: JSX.Element;
    closeButton: JSX.Element;
    summary: JSX.Element;
}

const LayoutDesktop = ({ mainContent, closeButton, summary }: LayoutDesktopProps): JSX.Element => {
    return (
        <div className={ styles.Wrapper }>
            <div className={ styles.Content }>
                <div className={ styles.HeaderWrapper }>
                    <div className={ styles.Header }>
                        Оформление заказа
                    </div>
                    <div>
                        { closeButton }
                    </div>
                </div>

                <div className={ styles.LayoutContentWrapper }>
                    <div className={ styles.MainContent }>
                        { mainContent }
                    </div>

                    <div className={ styles.Summary }>
                        { summary }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LayoutDesktop;
