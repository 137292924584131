import { 
    createSlice, 
    createAsyncThunk,
    PayloadAction,
} from '@reduxjs/toolkit';

import { deliveriesRequest } from '../api/deliveries';
import type { 
    DeliveriesState,
    AvailablePaymentMethodsMapping,
    CourierDelivery,
    PostDelivery,
    Pvz,
    Delivery,
    Product,
} from './types';
import {
    selectDeliveryOrDeliveryType,
    getProducts,
    getCity,
    getDeliveryType,
    getSelectedDeliveryOrDeliveryType,
} from './orderSlice';
import type { RootState } from './index';
import { LoadingState } from '../types';

const initialState: DeliveriesState = {
    loadingState: 'not_started',

    deliveries: [],
    pvzList: [],
};

interface GetDeliveryMethodsByCityItem {
    name: string;
    id: string;
    type: DeliveryType;
    delivery_days: number;
    delivery_price: number;
    payments: AvailablePaymentMethodsMapping;
}
interface GetDeliveryMethodsByCityResponse {
    delivery_methods: Array<GetDeliveryMethodsByCityItem>;
}

type DeliveryType = 'courier' | 'post' | 'pvz';

const _preprocessProducts = (products: Product[]) => {
    return JSON.stringify(products.map(item => ({
        id: item.id,
        price_insurance: item.price,
        price_to_pay: item.price,
        weight: item.weight,
        x: item.dimensions.x,
        y: item.dimensions.y,
        z: item.dimensions.z,
        quantity: item.quantity,
    })));
};

const _getDelivery = (
    currentDeliveryType: DeliveryType | undefined,
    deliveries: Delivery[]
) => {
    if (!currentDeliveryType){
        return deliveries[0];
    }
    const delivery = deliveries.find(item => item.type === currentDeliveryType);
    if (delivery) {
        return delivery;
    } else if (currentDeliveryType === 'courier' && deliveries.find(item => item.type === 'post')) {
        return deliveries.find(item => item.type === 'post');
    } else if (currentDeliveryType === 'post' && deliveries.find(item => item.type === 'courier')) {
        return deliveries.find(item => item.type === 'courier');
    } else {
        return deliveries[0];
    }
};

export const fetchDeliveries = createAsyncThunk<void, void, { state: RootState }>(
    'deliveries/fetchDeliveries',
    async (_, { getState, dispatch }) => {
        const state = getState();
        
        const city = getCity(state);
        const products = getProducts(state);

        const response = await deliveriesRequest<GetDeliveryMethodsByCityResponse>(
            '/api/v1/get_delivery_methods_by_city', 
            { body: { 
                id: city.fias_id || null,
                products: _preprocessProducts(products),
            } }
        );

        const deliveries = response.delivery_methods
            .map((item: GetDeliveryMethodsByCityResponse['delivery_methods'][0]) => ({
                id: item.id,
                name: item.name,
                type: item.type,
                days: item.delivery_days,
                price: item.delivery_price,
                payments: item.payments,
            }));

        dispatch(setDeliveries(deliveries));
        
        if (deliveries.length) {
            const currentDeliveryType = getDeliveryType(state) ||
                getSelectedDeliveryOrDeliveryType(state)?.deliveryType;

            const delivery = _getDelivery(currentDeliveryType, deliveries);
            const deliveryOrDeliveryType = delivery?.type !== 'pvz' ?
                { delivery: delivery as CourierDelivery | PostDelivery, deliveryType: undefined } :
                { deliveryType: delivery.type, delivery: undefined };

            dispatch(selectDeliveryOrDeliveryType(deliveryOrDeliveryType));
        }
    },
);

export const fetchPvzList = createAsyncThunk<Pvz[], void, { state: RootState }>(
    'deliveries/fetchPvzList',
    async (_, { getState, dispatch }) => {
        const state = getState();

        const city = getCity(state);
        const products = getProducts(state);

        const { pvzs } = await deliveriesRequest<{ pvzs: Pvz[] }>(
            '/widget/pvzs/get/',
            { 
                body: {
                    city_code: city.fias_id || null,
                    products: _preprocessProducts(products),
                }
            }
        );
        
        dispatch(setPvzList(pvzs));

        return pvzs;
    }
);

export const deliveriesSlice = createSlice({
    name: 'deliveries',
    initialState,
    reducers: {
        setDeliveries: (state: DeliveriesState, { payload }: PayloadAction<Delivery[]>) => {
            state.deliveries = payload;
        },

        setPvzList: (state: DeliveriesState, { payload }: PayloadAction<Pvz[]>) => {
            state.pvzList = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDeliveries.pending, (state) => {
                state.loadingState = 'in_progress';
            })
            .addCase(fetchDeliveries.fulfilled, (state) => {
                state.loadingState = 'done';
            });
    },
});

export const { 
    setDeliveries,
    setPvzList,
} = deliveriesSlice.actions;

export const reducer = deliveriesSlice.reducer;

export const getDeliveriesLoadingState = (store: RootState): LoadingState => store.deliveries.loadingState;

export const getDeliveries = (store: RootState): RootState['deliveries']['deliveries'] => store.deliveries.deliveries;

export const getPvzList = (store: RootState): RootState['deliveries']['pvzList'] => store.deliveries.pvzList;
